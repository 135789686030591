import { ServiceFormCreate } from "components/Condominiums/Services/ServiceForm/ServiceFormCreate";
import { ServiceFormEdit } from "components/Condominiums/Services/ServiceForm/ServiceFormEdit";
import {
  CreateServiceFormLegend,
  EditServiceFormLegend
} from "components/Condominiums/Services/ServiceForm/ServiceFormLegends";

export const SERVICE_FORM_COMPONENTS_PER_TYPE = {
  edit: ServiceFormEdit,
  create: ServiceFormCreate,
};

export const SERVICE_FORM_LEGENDS_PER_TYPE = {
  edit: EditServiceFormLegend,
  create: CreateServiceFormLegend,
};
