import { RefObject } from "react";
import { useParams } from "react-router-dom";

import { Button, Container } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { Unit } from "hooks/useCondominiumUnitRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";


export interface UnitFormDataProps {
  number: string;
  block: string;
  maxGarage: number;
  rgi: string;
  ownerFullName: string;
  jointOwnerPrincipalFullName: string;
}

interface UnitFormProps {
  formRef: RefObject<FormHandles>;
  storeUnit: (data: Unit) => void;
  dataFormState: {
    dataForm: UnitFormDataProps,
    setDataForm: React.Dispatch<React.SetStateAction<UnitFormDataProps>>
  }
}

export const UnitForm = ({
  formRef,
  storeUnit,
  dataFormState: {setDataForm }
}: UnitFormProps) => {
  const { id: condominiumId } = useParams();

  const { formIsValid } = useYupValidation();

  const handleSubmitForm = async (data: Unit) => {
    if (!condominiumId) return;
    if (!(await formIsValid(formRef, FormTypes.STORE_UNIT, data)))
      return;
    storeUnit({...data, condominiumId });
  };

  const handleChangeForm = (_data?: object) => {
    if (!formRef.current) return;
    setDataForm(formRef.current.getData() as UnitFormDataProps);
  };

  return (
    <>
      <Container margin={0} justifyContent="left">
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          onChange={handleChangeForm}
        >
          <Input name={"number"} label={"Número"} marginBottom={5} />
          <Input name={"block"} label={"Bloco"} marginBottom={5} />
          <Input
            type={"number"}
            name={"maxGarage"}
            label={"Máximo de Garagens"}
            marginBottom={5}
          />
          <Input
            name={"rgi"} label={"RGI"} marginBottom={5} />
          <Input
            name={"ownerFullName"}
            label={"Nome completo do Proprietário"}
            marginBottom={5}
          />
          <Input
            name={"jointOwnerPrincipalFullName"}
            label={"Nome completo do principal Co-proprietário"}
            marginBottom={5}
          />
          <Button
            type={"submit"}
            marginTop={5}
            colorScheme={"teal"}
            marginBottom={5}
          >
            Salvar
          </Button>
        </Form>
      </Container>
    </>
  );
};
