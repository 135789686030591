import { UnitFormCreate } from "components/Condominiums/Units/UnitForm/UnitFormCreate";
import { UnitFormEdit } from "components/Condominiums/Units/UnitForm/UnitFormEdit";
import {
  CreateUnitFormLegend,
  EditUnitFormLegend
} from "components/Condominiums/Units/UnitForm/UnitFormLegends";

export const UNIT_FORM_COMPONENTS_PER_TYPE = {
  edit: UnitFormEdit,
  create: UnitFormCreate,
};

export const UNIT_FORM_LEGENDS_PER_TYPE = {
  edit: EditUnitFormLegend,
  create: CreateUnitFormLegend,
};
