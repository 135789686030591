import { RefObject } from "react";

import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { ValidationError } from "yup";

import { FormTypes } from "services/utils/FormTypes";

interface IYupErrors {
  [key: string]: string;
}

export const useYupValidation = () => {
  async function validateFormLogin(data: object) {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Digite um e-mail válido.")
        .required("E-mail é obrigatório."),
      password: Yup.string().required("Senha é obrigatória."),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateFormVerifyEmail(data: object) {
    const schema = Yup.object().shape({
      password: Yup.string().required("Senha é obrigatória."),
      repassword: Yup.string()
        .required("Esse campo é obrigatório")
        .oneOf([Yup.ref("password"), null], "Senhas precisam ser iguais"),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateStoreCondominium(data: object) {
    const schema = Yup.object().shape({
      full_name: Yup.string().required("Informe o nome completo do condominio"),
      address: Yup.string()
        .min(10)
        .required("Informe o endereço do condominio"),
      cnpj: Yup.string()
        .matches(/\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/)
        .required("Informe um numero de CNPJ"),
      phone: Yup.string()
        .matches(
          /^[0-9]+$/,
          "Informe apenas os numeros com o DDD. Ex: 21988887777"
        )
        .required("Informe um telefone valido"),
      email: Yup.string().email().required("Informe um e-mail valido"),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateStoreUnit(data: object) {
    const schema = Yup.object().shape({
      number: Yup.string().required("Informe o número da unidade."),
      block: Yup.string().required("Informe o bloco da unidade."),
      maxGarage: Yup.number().required("Informe o total de Garagens."),
      rgi: Yup.string().required("Informe o RGI da unidade."),
      ownerFullName: Yup.string().required("Informe o nome completo do proprietário."),
      jointOwnerPrincipalFullName: Yup.string().required("Informe o nome completo do co-proprietário."),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateStoreService(data: object) {
    const schema = Yup.object().shape({
      name: Yup.string().required("Informe o nome do serviço."),
      date: Yup.date().required("Informe a data do serviço."),
      price: Yup.string().required("Informe o preço do serviço."),
      serviceOrder: Yup.string().required("Informe a ordem do serviço."),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateStoreProvider(data: object) {
    const schema = Yup.object().shape({
      fullName: Yup.string().required("Informe o nome do fornecedor."),
      address: Yup.string()
        .min(10)
        .required("Informe o endereço do fornecedor."),
      cnpj: Yup.string()
        .matches(/\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/)
        .required("Informe um número de CNPJ."),
      phone: Yup.string()
        .matches(
          /^[0-9]+$/,
          "Informe apenas números com o DDD. Ex: 21988887777."
        )
        .required("Informe um telefone válido."),
      email: Yup.string().email().required("Informe um e-mail válido."),
      segment: Yup.string().required("Informe o segmento do fornecedor."),
      reviews: Yup.number().required("Informe a quantidade de reviews."),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateStoreEmployee(data: object) {
    const schema = Yup.object().shape({
      fullName: Yup.string().required("Informe o nome completo do funcionário."),
      role: Yup.string().required("Informe o cargo do funcionário."),
      admissionDate: Yup.date().required("Informe a data de admissão do funcionário."),
      outsourced: Yup.string().required("Informe se é o funcionário é terceirizado."),
      salary: Yup.string().matches(
        /^[0-9]+$/,
        "Informe apenas números para o salário. Ex: 2500."
      ).required("Informe o salário do funcionário."),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateStoreJointOwner(data: object) {
    const schema = Yup.object().shape({
      fullName: Yup.string().required("Informe o nome completo."),
      cpf: Yup.string().matches(
        /^[0-9]+$/,
        "Informe apenas números para o cpf. Ex: 00011100010."
      ).notRequired(),
      rg: Yup.string().matches(
        /^[0-9]+$/,
        "Informe apenas números para o cpf. Ex: 00011100010."
      ).notRequired(),
      email: Yup.string().email("Informe um e-mail válido"),
      cellphone: Yup.string().matches(
        /^[0-9]+$/,
        "Informe apenas números para o telefone. Ex: 2199998888."
      ).notRequired(),
      phone: Yup.string().matches(
        /^[0-9]+$/,
        "Informe apenas números para o telefone. Ex: 2199998888."
      ),
      date: Yup.date().notRequired()
    }).notRequired();

    await schema.validate(data, {
      abortEarly: false,
    });
  }
  

  async function validateForm(data: object, formType: string) {
    switch (formType) {
      case FormTypes.LOGIN:
        await validateFormLogin(data);
        break;
      case FormTypes.VERIFY_EMAIL:
        await validateFormVerifyEmail(data);
        break;
      case FormTypes.STORE_CONDOMINIUM:
        await validateStoreCondominium(data);
        break;
      case FormTypes.STORE_UNIT:
        await validateStoreUnit(data);
        break;
      case FormTypes.STORE_SERVICE:
        await validateStoreService(data);
        break;
      case FormTypes.STORE_PROVIDER:
        await validateStoreProvider(data);
        break;
      case FormTypes.STORE_EMPLOYEE:
        await validateStoreEmployee(data);
        break;
      case FormTypes.STORE_JOINT_OWNER:
        await validateStoreJointOwner(data);
        break;
      default:
        break;
    }
  }

  const errorHandlerYupValidation = (
    error: Yup.ValidationError,
    formRef: RefObject<FormHandles> | undefined
  ) => {
    const errorMessages: IYupErrors = {};
    error.inner.forEach((error) => {
      if (error.path) {
        errorMessages[error.path] = error.message;
      }
    });
    if (!formRef || !formRef.current) return;
    formRef.current.setErrors(errorMessages);
  };

  const formIsValid = async (
    formRef: RefObject<FormHandles>,
    formType: string,
    data: object
  ) => {
    try {
      await validateForm(data, formType);
      formRef?.current?.setErrors({});
      return true;
    } catch (e) {
      const err = e as ValidationError;
      errorHandlerYupValidation(err, formRef);
      return false;
    }
  };

  return { formIsValid };
};
