

import { useToken } from "hooks/useToken";

import api from "services/api";

export interface Unit {
  condominiumId: string;
  number: string;
  block: string;
  maxGarage: number;
  rgi: string;
  ownerFullName: string;
  jointOwnerPrincipalFullName: string;
  id: string;
}

interface UnitDTO {
  condominium_id: string;
  number: string;
  block: string;
  max_garage: number;
  rgi: string;
  owner_fullname: string;
  joint_owner_principal_fullname: string;
  id: string;
}

interface CondominiumUnitsRepositoryGetListResponse {
  data: {
    data: UnitDTO[];
  };
}

interface CondominiumUnitRepositoryGetOneResponse {
  data: {
    data: UnitDTO;
  };
}

type CondominiumUnits = Unit[];
type CondominiumUnitsDTO = UnitDTO[];

export const useCondominiumUnitRepository = () => {
  const { sessionRequestDefaultConfig } = useToken();

  const translateUnitFromDTO = (unitDTO: UnitDTO) => {
    return {
      condominiumId: unitDTO.condominium_id,
      number: unitDTO.number,
      block: unitDTO.block,
      maxGarage: unitDTO.max_garage,
      rgi: unitDTO.rgi,
      ownerFullName: unitDTO.owner_fullname,
      jointOwnerPrincipalFullName:
        unitDTO.joint_owner_principal_fullname,
      id: unitDTO.id,
    };
  };

  const translateUnitToDTO = (unit: Unit) => {
    return {
      condominium_id: unit.condominiumId,
      number: unit.number,
      block: unit.block,
      max_garage: unit.maxGarage,
      rgi: unit.rgi,
      owner_fullname: unit.ownerFullName,
      joint_owner_principal_fullname:
        unit.jointOwnerPrincipalFullName,
      id: unit.id,
    };
  };

  const adaptFromDTOList = (DTO: CondominiumUnitsDTO) => {
    return DTO.map((unit: UnitDTO) =>
      translateUnitFromDTO(unit));
  };

  const adaptFromDTO = (condominiumUnitDTO: UnitDTO) => {
    return translateUnitFromDTO(condominiumUnitDTO);
  };

  const adaptToDTO = (unit: Unit) => {
    return translateUnitToDTO(unit);
  };

  const getCondominiumUnits = async(condominiumId: string):
    Promise<CondominiumUnits> => {
    const response: CondominiumUnitsRepositoryGetListResponse =
      await api.get(
        `unit/?condominium_id=${condominiumId}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  const getCondominiumUnit = async(id: string): Promise<Unit> => {
    const response: CondominiumUnitRepositoryGetOneResponse =
      await api.get(`unit/${id}`, sessionRequestDefaultConfig);
    return adaptFromDTO(response.data.data);
  };

  const postCondominiumUnit = async(data: Unit):
    Promise<Unit> => {
    const response: CondominiumUnitRepositoryGetOneResponse =
      await api.post(
        "unit",
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const putCondominiumUnit = async(data: Unit, id: string):
    Promise<Unit> => {
    const response: CondominiumUnitRepositoryGetOneResponse =
      await api.put(
        `unit/${id}`,
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const deleteCondominiumUnit = async(id: string):
    Promise<CondominiumUnits> => {
    const response: CondominiumUnitsRepositoryGetListResponse =
      await api.delete(
        `unit/${id}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  return {
    getCondominiumUnits,
    getCondominiumUnit,
    postCondominiumUnit,
    deleteCondominiumUnit,
    putCondominiumUnit
  };
};
