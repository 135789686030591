import { useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { Employee } from "hooks/useCondominiumEmployeeRepository";
import { useEffectGetOneEmployee } from "hooks/useEffectGetOneEmployee";

import { Loading } from "components/Loading";

import { NUMBER_DEFAULT_VALUE, STRING_DEFAULT_VALUE } from "utils/constants";
import { currencyFormatter, dateFormatter } from "utils/format";

import { initialEmployeeState } from "../constants";
import { EmployeePreview } from "../EmployeePreview";
import { EmployeeDetailsContainer } from "./EmployeeDetailsContainer";

export const EmployeeDetails = () => {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<Employee>(
    initialEmployeeState
  );

  useEffectGetOneEmployee(setEmployee, setLoading);

  return (
    <EmployeeDetailsContainer fullName={employee.fullName} >
      {loading ? renderLoading(loading) : renderEmployeeDetails(employee)}
    </EmployeeDetailsContainer>
  );
};

const renderLoading = (loading: boolean) => {
  return (
    <>
      <Loading visible={loading} />
    </>
  );
};

const renderEmployeeDetails = (data: Employee) => {
  const formattedAdmissionDate = (
    data.admissionDate && dateFormatter.format(new Date(data.admissionDate))
  );
  const formattedSalary = (
    data.salary && currencyFormatter.format(data.salary)
  );

  return (
    <Flex justify={"space-between"}>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Nome completo"}</Text>
          <Text color={"gray.600"}>{data.fullName || STRING_DEFAULT_VALUE} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Cargo"}</Text>
          <Text color={"gray.600"}>{data.role || STRING_DEFAULT_VALUE} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Data da Admissão"}</Text>
          <Text color={"gray.600"}>{formattedAdmissionDate || STRING_DEFAULT_VALUE}</Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Terceirizado"}</Text>
          <Text color={"gray.600"}>
            {data.outsourced || STRING_DEFAULT_VALUE}
          </Text>
        </Box>
      </Box>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Salário"}</Text>
          <Text color={"gray.600"}>
            { formattedSalary || NUMBER_DEFAULT_VALUE}
          </Text>
        </Box>
      </Box>
      <Box width={320}>
        <EmployeePreview
          fullName={data.fullName}
          admissionDate={data.admissionDate}
          role={data.role}
          id={data.id}
        />
      </Box>
    </Flex>
  );
};
