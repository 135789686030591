import { Box, Heading } from "@chakra-ui/react";

interface ProviderDetailsContainerProps {
  children: JSX.Element;
  fullName: string;
}

export const ProviderDetailsContainer = ({
  children,
  fullName,
}: ProviderDetailsContainerProps) => {
  return (
    <Box width={"100%"}>
      <Heading>Fornecedor {fullName}</Heading>
      {children}
    </Box>
  );
};
