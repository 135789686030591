export enum ActionTypes {
  addSyndicInfo = "ADD_SYNDIC_INFO",
}

export interface ISyndicOrganization {
  id: number;
  name: string;
}

export interface ISyndicState {
  full_name: string;
  organization: ISyndicOrganization;
  id: number;
  email: string;
}

export interface ISyndicAction {
  type: string;
  payload: ISyndicState;
}
