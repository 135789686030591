import { Heading, Text } from "@chakra-ui/react";

export const CreateServiceFormLegend = () => {
  return (
    <legend>
      <Heading>Cadastro de Serviço</Heading>
      <Text>Informe os dados do serviço para cadastrar no Condomínio.</Text>
    </legend>
  );
};

export const EditServiceFormLegend = () => {
  return (
    <legend>
      <Heading>Alteração de Serviço</Heading>
      <Text>Altere os dados do serviço para salvar no Condomínio.</Text>
    </legend>
  );
};
