import { useNavigate } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { AxiosError, AxiosResponse } from "axios";

import api from "../../services/api";
import { ISyndicState } from "../../services/store/modules/syndic/types";
import { useToken } from "../useToken";

export interface IMeRepositoryResponse extends ISyndicState {
  error?: boolean;
  message?: object;
  auth: boolean;
}

export const useMeRepository = () => {
  const { token } = useToken();
  const navigator = useNavigate();
  const toast = useToast();

  function throwExceptionInToast(e: AxiosError): never {
    toast({
      title:
        "Sua sessão foi revogada. Realize o login novamente, por gentileza.",
      status: "warning",
    });
    navigator("/login");
    throw e;
  }

  async function meRepository(): Promise<AxiosResponse<IMeRepositoryResponse>> {
    try {
      return await api.get("me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      const err = e as AxiosError;
      throwExceptionInToast(err);
    }
  }

  return meRepository;
};
