import { CondominiumTabsProvider } from "layouts/CondominiumTabsProvider";
import { DashboardProvider } from "layouts/DashboardProvider";

import { ViewCondominium } from "pages/privatePages/Condominium";
import { ListEmployees } from "pages/privatePages/Condominium/ListEmployees";
import { ListProviders } from "pages/privatePages/Condominium/ListProviders";
import { ListServices } from "pages/privatePages/Condominium/ListServices";
import { ListUnits } from "pages/privatePages/Condominium/ListUnits";
import { SaveEmployee } from "pages/privatePages/Employee/SaveEmployee";
import { ViewEmployee } from "pages/privatePages/Employee/ViewEmployee";
import {HomePage} from "pages/privatePages/Home";
import { SaveJointOwner } from "pages/privatePages/JointOwner/SaveJointOwner";
import { ListCondominiums } from "pages/privatePages/ListCondominiums";
import { SaveProvider } from "pages/privatePages/Provider/SaveProvider";
import { ViewProvider } from "pages/privatePages/Provider/ViewProvider";
import { SaveCondominium } from "pages/privatePages/SaveCondominium";
import { SaveService } from "pages/privatePages/Service/SaveService";
import { ViewService } from "pages/privatePages/Service/ViewService";
import { SettingsPage } from "pages/privatePages/Settings";
import { SaveUnit } from "pages/privatePages/Unit/SaveUnit";
import { ViewUnit } from "pages/privatePages/Unit/ViewUnit";

import type { RouteDefault } from "routes/types";

import { condominiumTabsIndexes } from "components/Condominiums/constants";

const privateRoutes: RouteDefault[] = [
  {
    path: "/dashboard",
    element: <DashboardProvider><HomePage /></DashboardProvider>,
  },
  {
    path: "/dashboard/condominiums",
    element: (
      <DashboardProvider>
        <ListCondominiums />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id",
    element: (
      <DashboardProvider>
        <CondominiumTabsProvider
          tabIndex={condominiumTabsIndexes.condominiumDetails}
        >
          <ViewCondominium />
        </CondominiumTabsProvider>
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/units",
    element: (
      <DashboardProvider>
        <CondominiumTabsProvider
          tabIndex={condominiumTabsIndexes.condominiumUnits}
        >
          <ListUnits />
        </CondominiumTabsProvider>
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/units/unit/:unitId",
    element: (
      <DashboardProvider>
        <ViewUnit />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/units/unit/:unitId/edit",
    element: (
      <DashboardProvider>
        <SaveUnit />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/units/create",
    element: (
      <DashboardProvider>
        <SaveUnit />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/units/unit/:unitId/joint-owner/create",
    element: (
      <DashboardProvider>
        <SaveJointOwner />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/units/unit/:unitId/joint-owner/:jointOwnerId/edit",
    element: (
      <DashboardProvider>
        <SaveJointOwner />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/services",
    element: (
      <DashboardProvider>
        <CondominiumTabsProvider
          tabIndex={condominiumTabsIndexes.condominiumServices}
        >
          <ListServices />
        </CondominiumTabsProvider>
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/services/service/:serviceId/edit",
    element: (
      <DashboardProvider>
        <SaveService />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/services/create",
    element: (
      <DashboardProvider>
        <SaveService />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/services/service/:serviceId",
    element: (
      <DashboardProvider>
        <ViewService />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/providers",
    element: (
      <DashboardProvider>
        <CondominiumTabsProvider
          tabIndex={condominiumTabsIndexes.condominiumProviders}
        >
          <ListProviders />
        </CondominiumTabsProvider>
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/providers/provider/:providerId/edit",
    element: (
      <DashboardProvider>
        <SaveProvider />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/providers/create",
    element: (
      <DashboardProvider>
        <SaveProvider />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/providers/provider/:providerId",
    element: (
      <DashboardProvider>
        <ViewProvider />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/employees",
    element: (
      <DashboardProvider>
        <CondominiumTabsProvider
          tabIndex={condominiumTabsIndexes.condominiumEmployees}
        >
          <ListEmployees />
        </CondominiumTabsProvider>
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/employees/employee/:employeeId/edit",
    element: (
      <DashboardProvider>
        <SaveEmployee />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/employees/create",
    element: (
      <DashboardProvider>
        <SaveEmployee />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/condominium/:id/employees/employee/:employeeId",
    element: (
      <DashboardProvider>
        <ViewEmployee />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/create",
    element: (
      <DashboardProvider>
        <SaveCondominium />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/condominiums/edit/:id",
    element: (
      <DashboardProvider>
        <SaveCondominium />
      </DashboardProvider>
    ),
  },
  {
    path: "/dashboard/tasks",
    element: <DashboardProvider>/dashboard/task </DashboardProvider>,
  },
  {
    path: "/dashboard/organization",
    element: <DashboardProvider> /dashboard/organization </DashboardProvider>,
  },
  {
    path: "/dashboard/settings",
    element: <DashboardProvider> <SettingsPage /> </DashboardProvider>,
  },
];

export { privateRoutes };
