import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { JointOwner, useJointOwnerRepository } from "hooks/useJointOwnerRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

export const useEffectGetOneJointOwner = (
  setJointOwner: React.Dispatch<React.SetStateAction<JointOwner>>,
  setLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined = undefined
) => {
  const { jointOwnerId } = useParams();
  const { getJointOwner } = useJointOwnerRepository();
  const { abortRepository } = useRepositoryController();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    (async () => {
      if (!jointOwnerId) return;
      const jointOwner = await getJointOwner(jointOwnerId);
      setJointOwner(jointOwner);
      if (setLoading) {
        setLoading(false);
      }
    })();
    return () => abortRepository();
  }, [jointOwnerId]);
};

