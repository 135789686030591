import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Unit, useCondominiumUnitRepository } from "hooks/useCondominiumUnitRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

export const useEffectGetOneUnit = (
  setUnit: React.Dispatch<React.SetStateAction<Unit>>,
  setLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined = undefined
) => {
  const { unitId } = useParams();
  const { getCondominiumUnit } = useCondominiumUnitRepository();
  const { abortRepository } = useRepositoryController();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    (async () => {
      if (!unitId) return;
      const unit = await getCondominiumUnit(unitId);
      setUnit(unit);
      if (setLoading) {
        setLoading(false);
      }
    })();
    return () => abortRepository();
  }, [unitId]);
};

