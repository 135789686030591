import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Employee, useCondominiumEmployeeRepository } from "hooks/useCondominiumEmployeeRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

export const useEffectGetOneEmployee = (
  setEmployee: React.Dispatch<React.SetStateAction<Employee>>,
  setLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined = undefined
) => {
  const { employeeId } = useParams();
  const { getCondominiumEmployee } = useCondominiumEmployeeRepository();
  const { abortRepository } = useRepositoryController();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    (async () => {
      if (!employeeId) return;
      const employee = await getCondominiumEmployee(employeeId);
      if (setLoading) {
        setLoading(false);
      }
      setEmployee(employee);
    })();
    return () => abortRepository();
  }, [employeeId]);
};
