import "@fontsource/prompt";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";

import { SystemProvider } from "components/Provider/SystemProvider";

import { SystemRoutes } from "./routes";
import store from "./services/store";
import theme from "./theme";

export const App: React.FC = () => {
  return (
    <CookiesProvider>
      <ReduxProvider store={store}>
        <ChakraProvider theme={theme}>
          <SystemProvider>
            <Router>
              <SystemRoutes />
            </Router>
          </SystemProvider>
        </ChakraProvider>
      </ReduxProvider>
    </CookiesProvider>
  );
};
