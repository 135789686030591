import React from "react";

import { Box, Center } from "@chakra-ui/react";

import { FormSettings } from "components/Settings/FormSettings";

export const SettingsPage = () => {
  return (
    <>
      <Box>
        <Center>
          <FormSettings />
        </Center>
      </Box>
    </>
  );
};
