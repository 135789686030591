import { LoginPage } from "pages/publicPages/Login";
import { NotFoundPage } from "pages/publicPages/NotFound";
import { VerifyEmailPage } from "pages/publicPages/VerifyEmail";

import type { RouteDefault } from "routes/types";

const publicRoutes: RouteDefault[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/verifyEmail",
    element: <VerifyEmailPage />,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
];
export { publicRoutes };
