import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import imageCondominium from "assets/images/cond.svg";

import {
  IDashboardRepositoryResponse,
  useDashboardRepository,
} from "hooks/useDashboardRepository";

import { CardOnboarding } from "components/Onboarding/CardOnboarding";
import { CardUniqueInformation } from "components/Onboarding/CardUniqueInformation";

import { IStoreState } from "services/store/modules/types";


const initialStateDashboardInformation = {
  countUnit: 0,
  countCondominium: 0,
  countJointOwner: 0,
  countPendingTask: 0,
};

export const HomePage: React.FC = () => {
  const navigator = useNavigate();

  const dashboardRepository = useDashboardRepository();
  const [dashboardInformation, setDashboardInformation] =
    useState<IDashboardRepositoryResponse>(initialStateDashboardInformation);

  const { full_name } = useSelector((state: IStoreState) => state.syndic);

  function handleClickButtonRegisterCondominium() {
    navigator("condominiums/create");
  }

  const useEffectDashboardRepositorySetState = () =>
    useEffect(() => {
      (async () => {
        const { data } = await dashboardRepository();
        setDashboardInformation(data);
      })();
    }, []);

  useEffectDashboardRepositorySetState();

  return (
    <div>
      <Box
        bg={useColorModeValue("gray.50", "gray.900")}
        px={2}
        py={2}
        mb={2}
        borderRadius={5}
      >
        <Heading
          color={useColorModeValue("gray.700", "white")}
          fontSize={"xl"}
          fontFamily={"body"}
        >
          Bem vindo, {full_name}!
        </Heading>
      </Box>

      <Flex justify={"space-between"} pr={5} pt={5} pb={5}>
        <CardUniqueInformation
          title={"Condominios Cadastrados"}
          value={dashboardInformation.countCondominium}
        />

        <CardUniqueInformation title={"Unidades Cadastradas"} value={dashboardInformation.countUnit} />

        <CardUniqueInformation title={"Condôminos Cadastrados"} value={dashboardInformation.countCondominium} />

        <CardUniqueInformation title={"Tarefas Pendentes"} value={dashboardInformation.countPendingTask} />
      </Flex>

      <CardOnboarding
        buttonTitle="Cadastrar condomínio"
        title="Cadastre seus Condomínios"
        handleClickButton={handleClickButtonRegisterCondominium}
        descriptions="Cadastre seus condomínios no <b>Monosindico<b>"
        imageCard={imageCondominium}
      />
    </div>
  );
};
