import { RefObject, useState } from "react";

import { Button, Container } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { ICondominium } from "hooks/useCondominiumRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";

import { CondominiumPreview } from "../CondominiumPreview";
import { initialStateCondominium } from "../constants";

interface IFormData {
  full_name: string;
  address: string;
  cnpj: string;
  email: string;
  phone: string;
  unit_quantity: number;
  name_subsyndic: string;
}

interface ICondominiumFormProps {
  formRef: RefObject<FormHandles>;
  storeCondominium: (data: ICondominium) => void;
}

export const CondominiumForm = ({
  formRef,
  storeCondominium,
}: ICondominiumFormProps) => {
  const [dataForm, setDataForm] = useState<IFormData>(initialStateCondominium);
  const { full_name, address } = dataForm;

  const { formIsValid } = useYupValidation();

  const handleSubmitForm = async (data: ICondominium) => {
    if (!(await formIsValid(formRef, FormTypes.STORE_CONDOMINIUM, data)))
      return;
    storeCondominium(data);
  };

  const handleChangeForm = (_data?: object) => {
    if (!formRef.current) return;
    setDataForm(formRef.current.getData() as IFormData);
  };

  return (
    <>
      <Container margin={0} justifyContent="left">
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          onChange={handleChangeForm}
        >
          <Input name={"full_name"} label={"Nome"} marginBottom={5} />
          <Input name={"address"} label={"Endereço"} marginBottom={5} />
          <Input
            name={"cnpj"} label={"CNPJ do Condomínio"} marginBottom={5} />
          <Input
            name={"email"} label={"E-mail do Responsável"} marginBottom={5} />
          <Input
            name={"phone"}
            label={"Telefone do Responsável"}
            marginBottom={5}
          />
          <Input
            name={"unit_quantity"}
            label={"Quantidade de Unidades"}
            marginBottom={5}
            type="number"
          />
          <Input
            name={"name_subsyndic"}
            label={"Nome do Sub-Síndico"}
            marginBottom={5}
          />
          <Button
            type={"submit"}
            marginTop={5}
            colorScheme={"teal"}
            marginBottom={5}
          >
            Salvar
          </Button>
        </Form>
      </Container>
      <CondominiumPreview full_name={full_name} address={address} />
    </>
  );
};
