import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Service, useCondominiumServiceRepository } from "hooks/useCondominiumServiceRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

export const useEffectGetOneService = (
  setService: React.Dispatch<React.SetStateAction<Service>>,
  setLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined = undefined
) => {
  const { serviceId } = useParams();
  const { getCondominiumService } = useCondominiumServiceRepository();
  const { abortRepository } = useRepositoryController();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    (async () => {
      if (!serviceId) return;
      const service = await getCondominiumService(serviceId);
      if (setLoading) {
        setLoading(false);
      }
      setService(service);
    })();
    return () => abortRepository();
  }, [serviceId]);
};
