import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { SocialButton } from "components/SocialButton";

export const Footer = () => {
  return (
    <Box
      h={"10vh"}
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text>© 2022 Monolito Software. Todos os direitos reservados.</Text>
        <Stack direction={"row"} spacing={6}>
          <SocialButton
            label={"LinkedIn"}
            href={"https://www.linkedin.com/company/monolito-software/about/"}
          >
            <FaLinkedin />
          </SocialButton>
          <SocialButton
            label={"Facebook"}
            href={"https://www.facebook.com/Monolito-Software-104210702048165/"}
          >
            <FaFacebook />
          </SocialButton>
          <SocialButton
            label={"Instagram"}
            href={
              "https://www.instagram.com/monolito_software/?utm_medium=copy_link"
            }
          >
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
};
