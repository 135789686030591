import { RefObject, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import { JointOwner, useJointOwnerRepository } from "hooks/useJointOwnerRepository";

import { makeMessageError } from "utils/axiosUtils";

import { JointOwnerForm, JointOwnerFormDataProps } from ".";
import { initialJointOwnerState } from "../../constants";

export const JointOwnerFormCreate = () => {
  const navigate = useNavigate();
  const { id: condominiumId } = useParams();
  const toast = useToast();
  const [dataForm, setDataForm] = useState<JointOwnerFormDataProps>(initialJointOwnerState);
  const dataFormState = {
    dataForm,
    setDataForm
  };
  const { postJointOwner } = useJointOwnerRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  const storeJointOwner = (data: JointOwner) => {
    postJointOwner(data)
      .then((_r) => {
        toast({
          title: `Condômino ${data.fullName} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/units/unit/${data.unitId}`
        );
      })
      .catch((err) => {
        console.log(err);
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <JointOwnerForm formRef={formRef} storeJointOwner={storeJointOwner} dataFormState={dataFormState} />
  );
};
