import React, { useEffect, useRef } from "react";

import {
  Input as ChakraInput,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useField } from "@unform/core";

interface InputProps {
  name: string;
  label: string;
  [x: string]: any; // eslint-disable-line
}

export const Input = ({ name, label, ...rest }: InputProps) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const isError = typeof error === "string";

  return (
    <FormControl isInvalid={isError}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ChakraInput
        ref={inputRef}
        defaultValue={defaultValue}
        focusBorderColor={"green.300"}
        {...rest}
      />
      {isError && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
