import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Provider, useCondominiumProviderRepository } from "hooks/useCondominiumProviderRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

export const useEffectGetOneProvider = (
  setProvider: React.Dispatch<React.SetStateAction<Provider>>,
  setLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined = undefined
) => {
  const { providerId } = useParams();
  const { getCondominiumProvider } = useCondominiumProviderRepository();
  const { abortRepository } = useRepositoryController();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    (async () => {
      if (!providerId) return;
      const provider = await getCondominiumProvider(providerId);
      if (setLoading) {
        setLoading(false);
      }
      setProvider(provider);
    })();
    return () => abortRepository();
  }, [providerId]);
};
