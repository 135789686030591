import { useNavigate, useParams } from "react-router-dom";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";

import { currencyFormatter, dateFormatter } from "utils/format";

interface Props {
  name: string;
  date: string;
  price: string;
  id?: string;
}

export const ServicePreview = ({
  id,
  name,
  date,
  price
}: Props) => {
  const navigator = useNavigate();
  const { id: condominiumId } = useParams();

  const handleNavigateToServiceDetails = () => {
    if (!id) return;
    navigator(`/dashboard/condominiums/condominium/${condominiumId}/services/service/${id}`);
  };

  const handleNavigateToServiceEditForm = () => {
    if (!id) return;
    navigator(
      `/dashboard/condominiums/condominium/${condominiumId}/services/service/${id}/edit`
    );
  };

  const formattedDate = (
    date && dateFormatter.format(new Date(date))
  ) || "";

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      maxHeight="min"
      minHeight={120}
      minWidth="120px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        onClick={handleNavigateToServiceDetails}
        style={{
          cursor: "pointer",
        }}
        p="6"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Text
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {name}
        </Text>
        <Text>{currencyFormatter.format(Number(price))}</Text>
        <Text>{formattedDate}</Text>
      </Box>
      {id && (
        <Button
          variant={"ghost"}
          onClick={handleNavigateToServiceEditForm}
          display="flex"
          mt="2"
          alignItems="center"
        >
          <EditIcon
            boxSize={6}
            bg="white"
            color="green"
            borderColor="gray.200"
          />
        </Button>
      )}
    </Box>
  );
};
