export enum ActionTypes {
  addSessionToken = "ADD_SESSION_TOKEN",
}

export interface IUserSessionState {
  token: string;
}

export interface IUserSessionAction {
  type: string;
  payload: {
    token: string;
  };
}
