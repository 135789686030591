

import { useToast } from "@chakra-ui/react";
import { AxiosError, AxiosResponse } from "axios";

import api from "../../services/api";
import { useToken } from "../useToken";

export interface IDashboardRepositoryResponse {
  countCondominium: number;
  countUnit: number;
  countJointOwner: number;
  countPendingTask: number;
}

export const useDashboardRepository = () => {
  const { token } = useToken();
  const toast = useToast();

  function throwExceptionInToast(e: AxiosError): never {
    toast({
      title:
        "Não foi possível recuperar as informações do Dashboard. Entre em contato com o suporte.",
      status: "warning",
    });
    throw e;
  }

  async function dashboardRepository(): Promise<AxiosResponse<IDashboardRepositoryResponse>> {
    try {
      return await api.get("dashboard", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      const err = e as AxiosError;
      throwExceptionInToast(err);
    }
  }

  return dashboardRepository;
};
