import { JointOwnerFormCreate } from "./JointOwnerFormCreate";
import { JointOwnerFormEdit } from "./JointOwnerFormEdit";
import { CreateJointOwnerFormLegend, EditJointOwnerFormLegend } from "./JointOwnerFormLegends";

export const JOINT_OWNER_COMPONENTS_PER_TYPE = {
  edit: JointOwnerFormEdit,
  create: JointOwnerFormCreate,
};

export const JOINT_OWNER_LEGENDS_PER_TYPE = {
  edit: EditJointOwnerFormLegend,
  create: CreateJointOwnerFormLegend,
};
