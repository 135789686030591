import { useEffect } from "react";
import { useParams } from "react-router-dom";

import type { ICondominium } from "hooks/useCondominiumRepository";
import { useCondominiumRepository } from "hooks/useCondominiumRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

export const useEffectGetOnlyOnceCondominiumStoreState = (
  setCondominium: React.Dispatch<React.SetStateAction<ICondominium>>,
  setLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined = undefined
) => {
  const { id } = useParams();
  const { getCondominium } = useCondominiumRepository();
  const { abortRepository } = useRepositoryController();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    (async () => {
      const { data } = await getCondominium(Number(id));
      if (setLoading) {
        setLoading(false);
      }
      setCondominium(data as ICondominium);
    })();
    return () => abortRepository();
  }, [id]);
};
