import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useToken } from "hooks/useToken";

import { privateRoutes } from "routes/private";
import { publicRoutes } from "routes/public";
import type { RouteDefault } from "routes/types";

export const SystemRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const token = useToken().token;

  useEffect(() => {
    if (location.pathname == "/" && !hasPrivateAccess(token))
      return navigate("login");

    if (isPrivateRoute(location.pathname) && !hasPrivateAccess(token))
      return navigate("login");

    if (location.pathname == "/" && hasPrivateAccess(token))
      return navigate("dashboard");
  }, [navigate, token, location]);

  const routes = makeMyRoutesSystem(hasPrivateAccess(token));

  return <Routes>{routes}</Routes>;
};

const makeMyRoutesSystem = (hasPrivateAccess: boolean) => {
  let routes: JSX.Element[] = [];
  if (hasPrivateAccess) {
    routes = [
      ...routes,
      ...privateRoutes.map(
        (route: RouteDefault): JSX.Element => (
          <Route key={route.path} path={route.path} element={route.element} />
        )
      ),
    ];
  }

  routes = [
    ...routes,
    ...publicRoutes.map(
      (route: RouteDefault): JSX.Element => (
        <Route key={route.path} path={route.path} element={route.element} />
      )
    ),
  ];

  return routes;
};

const isPrivateRoute = (path: string): boolean => {
  return !!privateRoutes.find((route: RouteDefault) => route.path === path);
};

const hasPrivateAccess = (token: string): boolean => {
  return !(token === "" || token === null || token === undefined);
};
