

import { useToken } from "hooks/useToken";

import api from "services/api";

export interface Provider {
  fullName: string;
  cnpj: string;
  address: string;
  email: string;
  phone: string;
  segment: string;
  reviews: number;
  id: number;
  condominiumId: number;
}

interface ProviderDTO {
  full_name: string;
  cnpj: string;
  address: string;
  email: string;
  phone: string;
  segment: string;
  reviews: number;
  id: number;
  condominium_id: number;
}

interface CondominiumProvidersRepositoryGetListResponse {
  data: {
    data: ProviderDTO[];
  };
}

interface CondominiumProviderRepositoryGetOneResponse {
  data: {
    data: ProviderDTO;
  };
}

type CondominiumProviders = Provider[];
type CondominiumProvidersDTO = ProviderDTO[];

export const useCondominiumProviderRepository = () => {
  const { sessionRequestDefaultConfig } = useToken();

  const translateProviderFromDTO = (providerDTO: ProviderDTO) => {
    return {
      condominiumId: providerDTO.condominium_id,
      fullName: providerDTO.full_name,
      cnpj:  providerDTO.cnpj,
      address: providerDTO.address,
      email: providerDTO.email,
      phone: providerDTO.phone,
      segment: providerDTO.segment,
      reviews: providerDTO.reviews,
      id: providerDTO.id,
    };
  };

  const translateProviderToDTO = (provider: Provider) => {
    return {
      condominium_id: provider.condominiumId,
      full_name: provider.fullName,
      cnpj:  provider.cnpj,
      address: provider.address,
      email: provider.email,
      phone: provider.phone,
      segment: provider.segment,
      reviews: provider.reviews,
      id: provider.id,
    };
  };

  const adaptFromDTOList = (DTO: CondominiumProvidersDTO) => {
    return DTO.map((provider: ProviderDTO) =>
      translateProviderFromDTO(provider));
  };

  const adaptFromDTO = (condominiumProviderDTO: ProviderDTO) => {
    return translateProviderFromDTO(condominiumProviderDTO);
  };

  const adaptToDTO = (provider: Provider) => {
    return translateProviderToDTO(provider);
  };

  const getCondominiumProviders = async(condominiumId: string):
    Promise<CondominiumProviders> => {
    const response: CondominiumProvidersRepositoryGetListResponse =
      await api.get(
        `provider/?condominium_id=${condominiumId}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  const getCondominiumProvider = async(id: string): Promise<Provider> => {
    const response: CondominiumProviderRepositoryGetOneResponse =
      await api.get(`provider/${id}`, sessionRequestDefaultConfig);
    return adaptFromDTO(response.data.data);
  };

  const postCondominiumProvider = async(data: Provider):
    Promise<Provider> => {
    const response: CondominiumProviderRepositoryGetOneResponse =
      await api.post(
        "provider",
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const putCondominiumProvider = async(data: Provider, id: string):
    Promise<Provider> => {
    const response: CondominiumProviderRepositoryGetOneResponse =
      await api.put(
        `provider/${id}`,
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const deleteCondominiumProvider = async(id: string):
    Promise<CondominiumProviders> => {
    const response: CondominiumProvidersRepositoryGetListResponse =
      await api.delete(
        `provider/${id}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  return {
    getCondominiumProviders,
    getCondominiumProvider,
    postCondominiumProvider,
    deleteCondominiumProvider,
    putCondominiumProvider
  };
};
