import { Provider } from "hooks/useCondominiumProviderRepository";

import {
  ID_DEFAULT_VALUE,
  NUMBER_DEFAULT_VALUE,
  STRING_EMPTY_VALUE
} from "utils/constants";

export const initialProviderState: Provider = {
  fullName: STRING_EMPTY_VALUE,
  cnpj: STRING_EMPTY_VALUE,
  address: STRING_EMPTY_VALUE,
  email: STRING_EMPTY_VALUE,
  phone: STRING_EMPTY_VALUE,
  segment: STRING_EMPTY_VALUE,
  reviews: NUMBER_DEFAULT_VALUE,
  id: ID_DEFAULT_VALUE,
  condominiumId: ID_DEFAULT_VALUE
};
