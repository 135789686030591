import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import {
  ICondominium,
  useCondominiumRepository,
} from "hooks/useCondominiumRepository";
import {
  useEffectGetOnlyOnceCondominiumStoreState
} from "hooks/useEffectGetOnlyOnceCondominiumStoreState";

import { CondominiumForm } from "components/Condominiums/CondominiumForm";
import { initialStateCondominium } from "components/Condominiums/constants";
import { Loading } from "components/Loading";

import { makeMessageError } from "utils/axiosUtils";

export const CondominiumFormEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [condominium, setCondominium] = useState<ICondominium>(
    initialStateCondominium
  );

  const { putCondominium } = useCondominiumRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  useEffectGetOnlyOnceCondominiumStoreState(setCondominium, setLoading);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.setData({ ...condominium });
  }, [condominium]);

  const storeCondominium = (data: ICondominium) => {
    if (!id) return;
    putCondominium(data, id)
      .then((_r) => {
        toast({
          title: `Condomínio ${data.full_name} salvo com sucesso.`,
          status: "success",
        });
        navigate(`/dashboard/condominiums/condominium/${id}`);
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <>
      {loading ? (
        <Loading visible={loading} />
      ) : (
        <CondominiumForm formRef={formRef} storeCondominium={storeCondominium} />
      )}
    </>
    
  );
};
