export enum FormTypes {
  LOGIN = "LOGIN",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  STORE_CONDOMINIUM = "STORE_CONDOMINIUM",
  STORE_UNIT = "STORE_UNIT",
  STORE_SERVICE = "STORE_SERVICE",
  STORE_PROVIDER = "STORE_PROVIDER",
  STORE_EMPLOYEE = "STORE_EMPLOYEE",
  STORE_JOINT_OWNER = "STORE_JOINT_OWNER",
}
