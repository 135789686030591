import { FlexContainer } from "layouts/FlexContainer";

import { ServiceDetails } from "components/Condominiums/Services/ServiceDetails";

export const ViewService = () => {
  return (
    <FlexContainer>
      <ServiceDetails />
    </FlexContainer>
  );
};
