import { ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

interface Props {
  children?: ReactNode;
}

export const FlexContainer = ({children, ...props}: Props) => {
  return (
    <Flex
      {...props}
      borderWidth={1}
      borderColor="gray.200"
      bg="gray.50"
      padding={5}
    >
      {children}
    </Flex>
  );
};
