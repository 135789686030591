import produce from "immer";
import { Reducer } from "redux";

import { ActionTypes, ISyndicState } from "./types";

const INITIAL_STATE: ISyndicState = {
  full_name: "",
  organization: {
    id: 0,
    name: "",
  },
  id: 0,
  email: ""
};

const Syndicate: Reducer<ISyndicState> = (
  state: ISyndicState = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.addSyndicInfo: {
        const { full_name, organization, id, email } = action.payload;

        draft.full_name = full_name;
        draft.organization = organization;
        draft.id = id;
        draft.email = email;

        break;
      }
      default: {
        return draft;
      }
    }
  });
};

export default Syndicate;
