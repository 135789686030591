import { ICondominium } from "hooks/useCondominiumRepository";

import {
  ID_DEFAULT_VALUE,
  NUMBER_DEFAULT_VALUE,
  STRING_DEFAULT_VALUE,
  STRING_EMPTY_VALUE
} from "utils/constants";

export const initialStateCondominium: ICondominium = {
  organization_id: null,
  full_name: STRING_EMPTY_VALUE,
  address: STRING_EMPTY_VALUE,
  cnpj: STRING_EMPTY_VALUE,
  email: STRING_EMPTY_VALUE,
  phone: STRING_EMPTY_VALUE,
  id: ID_DEFAULT_VALUE,
  type_charge: STRING_DEFAULT_VALUE,
  name_subsyndic: STRING_DEFAULT_VALUE,
  unit_quantity: NUMBER_DEFAULT_VALUE,
};

export const condominiumTabsIndexes = {
  condominiumDetails: 0,
  condominiumUnits: 1,
  condominiumServices: 2,
  condominiumProviders: 3,
  condominiumEmployees: 4,
};
