import { RefObject, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import appLogo from "assets/images/logo_dark.svg";

import {
  IVerifyEmailRepositoryError,
  IVerifyEmailRepositoryParams,
  useVerifyEmailRepository,
} from "hooks/useVerifyEmailRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";

export const VerifyEmailPage = () => {
  const formRef: RefObject<FormHandles> = useRef(null);

  const [searchParams] = useSearchParams();
  const tokenVerifyEmail = searchParams.get("tokenVerifyEmail") ?? "";
  const { formIsValid } = useYupValidation();
  const toast = useToast();

  const navigate = useNavigate();
  const verifyEmailRepository = useVerifyEmailRepository();

  async function handleSubmitVerifyEmail(body: IVerifyEmailRepositoryParams) {
    if (!(await formIsValid(formRef, FormTypes.VERIFY_EMAIL, body))) return;

    try {
      const { data } = await verifyEmailRepository(body, tokenVerifyEmail);

      toast({ title: data.message, status: "success" });

      return navigate("/login");
    } catch (error) {
      const err = error as IVerifyEmailRepositoryError;

      toast({ title: err.response.data.message, status: "error" });
    }
  }

  useEffect(() => {
    if (!tokenVerifyEmail) return navigate("/404");
  }, [tokenVerifyEmail, navigate]);

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box textAlign="center" py={10} px={6}>
        <Heading as="h4" size="md">
          Monosindico Dashboard
        </Heading>
        <Box flexShrink={0}>
          <Stack spacing={8} mx={"auto"} maxW={"sm"} py={0} px={6}>
            <Image src={appLogo} />
          </Stack>
        </Box>
        <Box py={10} px={6}>
          <Heading as="h4" size="md">
            Por favor, informe sua senha.
          </Heading>
        </Box>
        <Form
          ref={formRef}
          onSubmit={handleSubmitVerifyEmail}
          data-testid="login-verifyEmail"
        >
          <Input
            id="password"
            name="password"
            type="password"
            label="Senha"
            data-testid="verifyEmail-password"
          />
          <Input
            id="repassword"
            name="repassword"
            type="password"
            label="Confirme a Senha"
            data-testid="verifyEmail-repassword"
          />

          <Flex py={5}>
            <Button
              type="submit"
              colorScheme="green"
              data-testid="verifyEmail-submitButton"
            >
              Avançar
            </Button>
          </Flex>
        </Form>
      </Box>
    </Flex>
  );
};
