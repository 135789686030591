import { IconType } from "react-icons";
import {
  FiGrid,
  FiHome,
  FiSettings,
} from "react-icons/fi";

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
}

export const MenuLinkItems: Array<LinkItemProps> = [
  { name: "Início", icon: FiHome, href: "/dashboard" },
  { name: "Condomínios", icon: FiGrid, href: "/dashboard/condominiums" },
  { name: "Configurações", icon: FiSettings, href: "/dashboard/settings" },
];
