import { EmployeeFormCreate } from "components/Condominiums/Employees/EmployeeForm/EmployeeFormCreate";
import { EmployeeFormEdit } from "components/Condominiums/Employees/EmployeeForm/EmployeeFormEdit";
import {
  CreateEmployeeFormLegend,
  EditEmployeeFormLegend
} from "components/Condominiums/Employees/EmployeeForm/EmployeeFormLegends";

export const EMPLOYEE_FORM_COMPONENTS_PER_TYPE = {
  edit: EmployeeFormEdit,
  create: EmployeeFormCreate,
};

export const EMPLOYEE_FORM_LEGENDS_PER_TYPE = {
  edit: EditEmployeeFormLegend,
  create: CreateEmployeeFormLegend,
};
