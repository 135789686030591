import { Heading, Text } from "@chakra-ui/react";

export const CreateFormLegend = () => {
  return (
    <legend>
      <Heading>Cadastro de Condomínio</Heading>
      <Text>Informe os dados do condomínio para cadastrar no Monosíndico</Text>
    </legend>
  );
};

export const EditFormLegend = () => {
  return (
    <legend>
      <Heading>Alteração de Condomínio</Heading>
      <Text>Altere os dados do condomínio para salvar no Monosíndico</Text>
    </legend>
  );
};
