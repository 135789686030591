import { Box, Heading } from "@chakra-ui/react";

interface UnitDetailsContainerProps {
  children: JSX.Element;
  number: string;
  block: string;
}

export const UnitDetailsContainer = ({
  children,
  number,
  block,
}: UnitDetailsContainerProps) => {
  return (
    <Box width={"100%"}>
      <Heading>Unidade N°{number} - Bloco {block}</Heading>
      {children}
    </Box>
  );
};
