import {
  CondominiumFormCreate
} from "components/Condominiums/CondominiumForm/CondominiumFormCreate";
import {
  CondominiumFormEdit
} from "components/Condominiums/CondominiumForm/CondominiumFormEdit";

import { CreateFormLegend, EditFormLegend } from "./CondominiumFormLegends";

export const CONDOMINIUM_FORM_COMPONENTS_PER_TYPE = {
  edit: CondominiumFormEdit,
  create: CondominiumFormCreate,
};

export const CONDOMINIUM_FORM_LEGENDS_PER_TYPE = {
  edit: EditFormLegend,
  create: CreateFormLegend,
};
