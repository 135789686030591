import { Service } from "hooks/useCondominiumServiceRepository";

import { NUMBER_DEFAULT_VALUE, STRING_EMPTY_VALUE } from "utils/constants";

export const initialServiceState: Service = {
  condominiumId: STRING_EMPTY_VALUE,
  name: STRING_EMPTY_VALUE,
  date: STRING_EMPTY_VALUE,
  price: STRING_EMPTY_VALUE,
  isMaintenance: NUMBER_DEFAULT_VALUE,
  serviceOrder: STRING_EMPTY_VALUE,
  id: STRING_EMPTY_VALUE,
};
