import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

import {
  EMPLOYEE_FORM_COMPONENTS_PER_TYPE,
  EMPLOYEE_FORM_LEGENDS_PER_TYPE
} from "components/Condominiums/Employees/EmployeeForm/constants";
import { EmployeeFormType } from "components/Condominiums/Employees/EmployeeForm/types";

export const SaveEmployee = () => {
  const { employeeId } = useParams();
  const [type, setType] = useState<EmployeeFormType>("create");

  const EmployeeFormComponent = EMPLOYEE_FORM_COMPONENTS_PER_TYPE[type];
  const EmployeeFormLegendComponent = EMPLOYEE_FORM_LEGENDS_PER_TYPE[
    type
  ];

  function setupForm() {
    if (employeeId) {
      setType("edit");
    }
  }

  useEffect(() => {
    setupForm();
  }, [employeeId]);

  return (
    <Flex
      bg={useColorModeValue("gray.50", "gray.800")}
      justifyContent={"left"}
      padding={5}
    >
      <Box width="100%">
        <Box textAlign={"left"} py={10} px={2}>
          <EmployeeFormLegendComponent />
        </Box>
        <Box
          margin={2}
          display="flex"
          flexDirection={{
            base: "column-reverse",
            lg: "row"
          }}
          gap={10}
          justifyContent={{
            base: "center",
            lg: "space-between"
          }}
        >
          <EmployeeFormComponent />
        </Box>
      </Box>
    </Flex>
  );
};
