import { useNavigate } from "react-router-dom";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, Image } from "@chakra-ui/react";
import imageCondominium from "assets/images/cond.svg";

interface CondominiumPreviewProps {
  full_name: string;
  address: string;
  id?: number;
  size?: number;
  redirect?: boolean
}

export const CondominiumPreview = ({
  full_name,
  address,
  id,
  redirect = true
}: CondominiumPreviewProps) => {
  const navigator = useNavigate();

  function handleClickCardCondominium() {
    if (!id || !redirect) return;

    navigator(`condominium/${id}`);
  }

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      maxHeight="min"
      height="min-content"
      minWidth="120px"
    >
      <Image src={imageCondominium} />
      <Box p="6" display="flex" justifyContent="space-between">
        <Box
          onClick={handleClickCardCondominium}
          style={{
            cursor: "pointer",
          }}
        >
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {full_name}
          </Box>
          <Box>{address}</Box>
        </Box>
        {id && (
          <Button
            variant={"ghost"}
            onClick={() => navigator(`/dashboard/condominiums/edit/${id}`)}
            display="flex"
            mt="2"
            alignItems="center"
          >
            <EditIcon
              boxSize={6}
              bg="white"
              borderColor="gray.200"
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};
