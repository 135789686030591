import { RefObject } from "react";
import { useParams } from "react-router-dom";

import { Button, Container } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { JointOwner } from "hooks/useJointOwnerRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";


export interface JointOwnerFormDataProps {
  id: string;
  unitId: string;
  fullName: string;
  cpf: string;
  email: string;
  cellphone: string;
  phone: string;
  birthDate: string;
}

interface JointOwnerFormProps {
  formRef: RefObject<FormHandles>;
  storeJointOwner: (data: JointOwner) => void;
  dataFormState: {
    dataForm: JointOwnerFormDataProps,
    setDataForm: React.Dispatch<React.SetStateAction<JointOwnerFormDataProps>>
  }
}

export const JointOwnerForm = ({
  formRef,
  storeJointOwner,
  dataFormState: {setDataForm }
}: JointOwnerFormProps) => {
  const { unitId } = useParams();

  const { formIsValid } = useYupValidation();

  const handleSubmitForm = async (data: JointOwner) => {
    if (!unitId) return;
    if (!(await formIsValid(formRef, FormTypes.STORE_JOINT_OWNER, data)))
      return;
    storeJointOwner({...data, unitId });
  };

  const handleChangeForm = (_data?: object) => {
    if (!formRef.current) return;
    setDataForm(formRef.current.getData() as JointOwnerFormDataProps);
  };

  return (
    <>
      <Container margin={0} justifyContent="left">
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          onChange={handleChangeForm}
        >
          <Input name={"fullName"} label={"Nome Completo"} marginBottom={5} />
          <Input name={"cpf"} label={"CPF"} marginBottom={5} />
          <Input name={"rg"} label={"RG"} marginBottom={5} />
          <Input type={"email"} name={"email"} label={"E-mail"} marginBottom={5} />
          <Input name={"phone"} label={"Telefone"} marginBottom={5} />
          <Input name={"cellphone"} label={"Celular"} marginBottom={5} />
         
          <Button
            type={"submit"}
            marginTop={5}
            colorScheme={"teal"}
            marginBottom={5}
          >
            Salvar
          </Button>
        </Form>
      </Container>
    </>
  );
};
