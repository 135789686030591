import React, { RefObject, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import appLogo from "assets/images/logo_dark.svg";

import {
  ILoginRepositoryError,
  ILoginRepositoryParams,
  useLoginRepository,
} from "hooks/useLoginRepository";
import { useToken } from "hooks/useToken";
import { useYupValidation } from "hooks/useYupValidation";

import { Footer } from "layouts/Footer";

import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";

export const LoginPage: React.FC = () => {
  const formRef: RefObject<FormHandles> = useRef(null);
  const toast = useToast();
  const { setUserToken } = useToken();
  const loginRepository = useLoginRepository();
  const navigator = useNavigate();
  const { formIsValid } = useYupValidation();

  useEffect(() => {
    setUserToken("");
  }, [setUserToken]);

  async function executeLogin(body: ILoginRepositoryParams): Promise<void> {
    try {
      const { data } = await loginRepository(body);

      if (!data.auth) {
        toast({ title: data.message, status: "error" });
        return;
      }

      setUserToken(data.token);
      navigator("/dashboard");
    } catch (error) {
      const err = error as ILoginRepositoryError;
      toast({
        title: err?.response?.data?.message ?? "Error!",
        status: "error",
      });
    }
  }

  async function handleSubmitLogin(
    data: ILoginRepositoryParams
  ): Promise<void> {
    if (!(await formIsValid(formRef, FormTypes.LOGIN, data))) return;

    await executeLogin(data);
  }

  return (
    <>
      <Flex
        minH={"90vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Box p={4} display={{ md: "flex" }}>
          <Box flexShrink={0}>
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
              <Image src={appLogo} />
            </Stack>
          </Box>
          <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
              <Stack align={"center"}>
                <Heading fontSize={"4xl"} textAlign={"center"}>
                  MONOSÍNDICO
                </Heading>
                <Text fontSize={"lg"} color={"gray.600"}>
                  Entre na sua conta para acessar o Dashboard.
                </Text>
              </Stack>
              <Box
                rounded={"lg"}
                bg={useColorModeValue("white", "gray.700")}
                boxShadow={"lg"}
                p={8}
              >
                <Form
                  ref={formRef}
                  onSubmit={handleSubmitLogin}
                  data-testid="login-form"
                >
                  <Stack spacing={4}>
                    <Input
                      id="email"
                      name="email"
                      label="E-mail"
                      data-testid="login-email"
                    />
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      label="Senha"
                      data-testid="login-password"
                    />
                    <Stack spacing={10}>
                      <Stack
                        direction={{ base: "column", sm: "row" }}
                        align={"start"}
                        justify={"end"}
                      >
                        <Link color={"green.400"}>Esqueceu a senha?</Link>
                      </Stack>
                      <Button
                        data-testid="login-submit-button"
                        type="submit"
                        bg={"green.400"}
                        color={"white"}
                        _hover={{
                          bg: "green.500",
                        }}
                      >
                        Entrar
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Flex>
      <Footer />
    </>
  );
};
