import { AxiosError, AxiosResponse } from "axios";

import api from "services/api";

export interface IVerifyEmailRepositoryParams {
  password: string;
  repassword: string;
}

export interface IVerifyEmailRepositoryResponse {
  message: string | null;
  emailVerify: boolean | null;
  error: boolean | null;
}

export interface IVerifyEmailRepositoryError extends AxiosError {
  response: AxiosResponse<IVerifyEmailRepositoryResponse>;
}

export const useVerifyEmailRepository = () => {
  async function verifyEmailRepository(
    data: IVerifyEmailRepositoryParams,
    token: string
  ): Promise<AxiosResponse<IVerifyEmailRepositoryError>> {
    return await api.post("auth/verify-email", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return verifyEmailRepository;
};
