import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

import {
  UNIT_FORM_COMPONENTS_PER_TYPE,
  UNIT_FORM_LEGENDS_PER_TYPE
} from "components/Condominiums/Units/UnitForm/constants";
import { UnitFormType } from "components/Condominiums/Units/UnitForm/types";

export const SaveUnit = () => {
  const { unitId } = useParams();
  const [type, setType] = useState<UnitFormType>("create");

  const UnitFormComponent = UNIT_FORM_COMPONENTS_PER_TYPE[type];
  const UnitFormLegendComponent = UNIT_FORM_LEGENDS_PER_TYPE[
    type
  ];

  function setupForm() {
    if (unitId) {
      setType("edit");
    }
  }

  useEffect(() => {
    setupForm();
  }, [unitId]);

  return (
    <Flex
      bg={useColorModeValue("gray.50", "gray.800")}
      justifyContent={"left"}
      padding={5}
    >
      <Box width="100%">
        <Box textAlign={"left"} py={10} px={2}>
          <UnitFormLegendComponent />
        </Box>
        <Box
          margin={2}
          display="flex"
          flexDirection={{
            base: "column-reverse",
            lg: "row"
          }}
          gap={10}
          justifyContent={{
            base: "center",
            lg: "space-between"
          }}
        >
          <UnitFormComponent />
        </Box>
      </Box>
    </Flex>
  );
};
