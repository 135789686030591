import { Box, Heading } from "@chakra-ui/react";

interface EmployeeDetailsContainerProps {
  children: JSX.Element;
  fullName: string;
}

export const EmployeeDetailsContainer = ({
  children,
  fullName,
}: EmployeeDetailsContainerProps) => {
  return (
    <Box width={"100%"}>
      <Heading>Funcionário {fullName}</Heading>
      {children}
    </Box>
  );
};
