import { FlexContainer } from "layouts/FlexContainer";

import { UnitDetails } from "components/Condominiums/Units/UnitDetails";

export const ViewUnit = () => {
  return (
    <FlexContainer>
      <UnitDetails />
    </FlexContainer>
  );
};
