import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tab, TabList, Tabs } from "@chakra-ui/react";


interface Props {
  tabIndex: number;
  children: ReactNode;
}

export const CondominiumTabs = ({ tabIndex, children }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Tabs
      bg="gray.50"
      colorScheme='green'
      width="100%"
      isLazy
      index={tabIndex}
    >
      <TabList marginBottom={10}>
        <Tab
          onClick={() => navigate(`/dashboard/condominiums/condominium/${id}`)}
        >Informações Gerais</Tab>
        <Tab
          onClick={() => navigate(
            `/dashboard/condominiums/condominium/${id}/units`
          )}
        >Unidades</Tab>
        <Tab
          onClick={() => navigate(
            `/dashboard/condominiums/condominium/${id}/services`
          )}
        >Serviços</Tab>
        <Tab
          onClick={() => navigate(
            `/dashboard/condominiums/condominium/${id}/providers`
          )}
        >Fornecedores</Tab>
        <Tab
          onClick={() => navigate(
            `/dashboard/condominiums/condominium/${id}/employees`
          )}
        >Funcionários</Tab>
      </TabList>
      {children}
    </Tabs>
  );
};
