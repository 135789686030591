import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import { Service, useCondominiumServiceRepository } from "hooks/useCondominiumServiceRepository";
import { useEffectGetOneService } from "hooks/useEffectGetOneService";

import { Loading } from "components/Loading";

import { makeMessageError } from "utils/axiosUtils";

import { ServiceForm, ServiceFormDataProps } from ".";
import { initialServiceState } from "../constants";


export const ServiceFormEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  
  const { id: condominiumId, serviceId } = useParams();
  const [service, setService] = useState<Service>(
    initialServiceState
  );
  const [isMaintenance, setIsMaintenance] = useState<0 | 1>(0);
  const isMaintenanceState = {
    isMaintenance,
    setIsMaintenance
  };

  const [dataForm, setDataForm] = useState<ServiceFormDataProps>(initialServiceState);
  const dataFormState = {
    dataForm,
    setDataForm
  };

  const { putCondominiumService } = useCondominiumServiceRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  useEffectGetOneService(setService, setLoading);

  useEffect(() => {
    setIsMaintenance(service.isMaintenance as 0 | 1);
  }, [service]);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.setData({ ...service });
    dataFormState.setDataForm(formRef.current.getData() as ServiceFormDataProps);
  }, [service]);

  const storeService = (data: Service) => {
    if (!condominiumId) return;
    putCondominiumService(data, condominiumId)
      .then((_r) => {
        toast({
          title: `Serviço ${data.name} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/services/service/${serviceId}`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <>
      {loading ? (
        <Loading visible={loading} />
      ) : (
        <ServiceForm
          formRef={formRef}
          storeService={storeService}
          isMaintenanceState={isMaintenanceState}
          dataFormState={dataFormState}
        />
      )}
    </>
  );
};
