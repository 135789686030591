import { useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";




import { Service } from "hooks/useCondominiumServiceRepository";
import { useEffectGetOneService } from "hooks/useEffectGetOneService";

import { Loading } from "components/Loading";

import { dateFormatter } from "utils/format";

import { initialServiceState } from "../constants";
import { ServicePreview } from "../ServicePreview";
import { ServiceDetailsContainer } from "./ServiceDetailsContainer";

export const ServiceDetails = () => {
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<Service>(
    initialServiceState
  );

  useEffectGetOneService(setService, setLoading);

  return (
    <ServiceDetailsContainer name={service.name} >
      {loading ? renderLoading(loading) : renderServiceDetails(service)}
    </ServiceDetailsContainer>
  );
};

const renderLoading = (loading: boolean) => {
  return (
    <>
      <Loading visible={loading} />
    </>
  );
};

const renderServiceDetails = (data: Service) => {
  const formattedDate = (
    data.date && dateFormatter.format(new Date(data.date))
  ) || "";

  return (
    <Flex justify={"space-between"}>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Nome"}</Text>
          <Text color={"gray.600"}>{data.name || ""} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Preço"}</Text>
          <Text color={"gray.600"}>{data.price || ""} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Data"}</Text>
          <Text color={"gray.600"}>{formattedDate}</Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"É manutenção?"}</Text>
          <Text color={"gray.600"}>
            {data.isMaintenance === 1 ? "Sim" : "Não"}
          </Text>
        </Box>
      </Box>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Ordem do serviço"}</Text>
          <Text color={"gray.600"}>
            {data.serviceOrder || " "}
          </Text>
        </Box>
      </Box>
      <Box width={320}>
        <ServicePreview
          name={data.name}
          price={data.price}
          date={data.date}
          id={data.id}
        />
      </Box>
    </Flex>
  );
};
