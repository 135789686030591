import { useToken } from "hooks/useToken";

import api from "services/api";

export interface Employee {
  fullName: string;
  role: string;
  admissionDate: string;
  outsourced: string;
  salary: number;
  id: number;
  condominiumId: number;
}

interface EmployeeDTO {
  full_name: string;
  role: string;
  admission_date: string;
  outsourced: string;
  salary: number;
  id: number;
  condominium_id: number;
}

interface CondominiumEmployeesRepositoryGetListResponse {
  data: {
    data: EmployeeDTO[];
  };
}

interface CondominiumEmployeeRepositoryGetOneResponse {
  data: {
    data: EmployeeDTO;
  };
}

type CondominiumEmployees = Employee[];
type CondominiumEmployeesDTO = EmployeeDTO[];

export const useCondominiumEmployeeRepository = () => {
  const { sessionRequestDefaultConfig } = useToken();

  const translateEmployeeFromDTO = (employeeDTO: EmployeeDTO) => {
    return {
      fullName: employeeDTO.full_name,
      role: employeeDTO.role,
      admissionDate: employeeDTO.admission_date,
      outsourced: employeeDTO.outsourced,
      salary: employeeDTO.salary,
      id: employeeDTO.id,
      condominiumId: employeeDTO.condominium_id,
    };
  };

  const translateEmployeeToDTO = (employee: Employee) => {
    return {
      full_name: employee.fullName,
      role: employee.role,
      admission_date: employee.admissionDate,
      outsourced: employee.outsourced,
      salary: employee.salary,
      id: employee.id,
      condominium_id: employee.condominiumId,
    };
  };

  const adaptFromDTOList = (DTO: CondominiumEmployeesDTO) => {
    return DTO.map((employee: EmployeeDTO) =>
      translateEmployeeFromDTO(employee));
  };

  const adaptFromDTO = (condominiumEmployeeDTO: EmployeeDTO) => {
    return translateEmployeeFromDTO(condominiumEmployeeDTO);
  };

  const adaptToDTO = (employee: Employee) => {
    return translateEmployeeToDTO(employee);
  };

  const getCondominiumEmployees = async(condominiumId: string):
    Promise<CondominiumEmployees> => {
    const response: CondominiumEmployeesRepositoryGetListResponse =
      await api.get(
        `employee/?condominium_id=${condominiumId}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  const getCondominiumEmployee = async(id: string): Promise<Employee> => {
    const response: CondominiumEmployeeRepositoryGetOneResponse =
      await api.get(`employee/${id}`, sessionRequestDefaultConfig);
    return adaptFromDTO(response.data.data);
  };

  const postCondominiumEmployee = async(data: Employee):
    Promise<Employee> => {
    const response: CondominiumEmployeeRepositoryGetOneResponse =
      await api.post(
        "employee",
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const putCondominiumEmployee = async(data: Employee, id: string):
    Promise<Employee> => {
    const response: CondominiumEmployeeRepositoryGetOneResponse =
      await api.put(
        `employee/${id}`,
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const deleteCondominiumEmployee = async(id: string):
    Promise<CondominiumEmployees> => {
    const response: CondominiumEmployeesRepositoryGetListResponse =
      await api.delete(
        `employee/${id}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  return {
    getCondominiumEmployees,
    getCondominiumEmployee,
    postCondominiumEmployee,
    deleteCondominiumEmployee,
    putCondominiumEmployee
  };
};
