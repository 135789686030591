import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useMeRepository } from "hooks/useMeRepository";

import { SidebarWithHeader } from "layouts/SideBarWithHeader";

import { addSyndicInfo } from "services/store/modules/syndic/actions";

export const DashboardProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const meRepository = useMeRepository();

  const useMeOnlyOnceStoreReduxData = () =>
    useEffect(() => {
      (async () => {
        const { data } = await meRepository();
        dispatch(addSyndicInfo(data));
      })();
    }, []);

  useMeOnlyOnceStoreReduxData();

  return <SidebarWithHeader>{children}</SidebarWithHeader>;
};
