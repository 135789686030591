import { ActionTypes, ISyndicState } from "./types";

export function addSyndicInfo({ full_name, organization, id, email }: ISyndicState) {
  return {
    type: ActionTypes.addSyndicInfo,
    payload: {
      full_name,
      email,
      organization,
      id,
    },
  };
}
