import { Heading, Text } from "@chakra-ui/react";

export const CreateProviderFormLegend = () => {
  return (
    <legend>
      <Heading>Cadastro de Fornecedor</Heading>
      <Text>Informe os dados do fornecedor para cadastrar no Condomínio.</Text>
    </legend>
  );
};

export const EditProviderFormLegend = () => {
  return (
    <legend>
      <Heading>Alteração de Fornecedor</Heading>
      <Text>Altere os dados do fornecedor para salvar no Condomínio.</Text>
    </legend>
  );
};
