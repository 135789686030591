import { AxiosError } from "axios";

export function makeMessageError(error: AxiosError) {
  let message = "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!error.response) return message;

  for (const [attr, value] of Object.entries(
    error.response.data.message as { [key: string]: string[] }
  )) {
    message += `${attr}:${value.join("\n")}; `;
  }
  return message;
}
