import { Box, Heading, Stack, useColorModeValue } from "@chakra-ui/react";

import { Loading } from "components/Loading";

interface ICardUniqueInformationProps {
  title: string;
  value?: string|number;
}

const DEFAULT_VALUE = "0";

export const CardUniqueInformation = ({
  value = DEFAULT_VALUE,
  title = "...",
}: ICardUniqueInformationProps): JSX.Element => {
  return (
    <Stack alignSelf={"center"}>
      <Box
        w={"full"}
        width={240}
        height={160}
        textAlign={"center"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"md"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <div>
          <Heading size="sm" m={2}>
            {title}
          </Heading>
          {value == DEFAULT_VALUE ? (
            <Loading visible />
          ) : (
            <>
              <Heading>{value}</Heading>
            </>
          )}
        </div>
      </Box>
    </Stack>
  );
};
