import { Unit } from "hooks/useCondominiumUnitRepository";
import { JointOwner } from "hooks/useJointOwnerRepository";

import { NUMBER_DEFAULT_VALUE, STRING_EMPTY_VALUE } from "utils/constants";

export const initialUnitState: Unit = {
  condominiumId: STRING_EMPTY_VALUE,
  number: STRING_EMPTY_VALUE,
  block: STRING_EMPTY_VALUE,
  maxGarage: NUMBER_DEFAULT_VALUE,
  rgi: STRING_EMPTY_VALUE,
  ownerFullName: STRING_EMPTY_VALUE,
  jointOwnerPrincipalFullName: STRING_EMPTY_VALUE,
  id: STRING_EMPTY_VALUE,
};


export const initialJointOwnerState: JointOwner = {
  unitId: STRING_EMPTY_VALUE,
  fullName: STRING_EMPTY_VALUE,
  cpf: STRING_EMPTY_VALUE,
  rg: STRING_EMPTY_VALUE,
  email: STRING_EMPTY_VALUE,
  birthDate: STRING_EMPTY_VALUE,
  phone: STRING_EMPTY_VALUE,
  id: STRING_EMPTY_VALUE,
  cellphone: STRING_EMPTY_VALUE
};
