import { Box, Heading } from "@chakra-ui/react";

interface ServiceDetailsContainerProps {
  children: JSX.Element;
  name: string;
}

export const ServiceDetailsContainer = ({
  children,
  name,
}: ServiceDetailsContainerProps) => {
  return (
    <Box width={"100%"}>
      <Heading>Serviço {name}</Heading>
      {children}
    </Box>
  );
};
