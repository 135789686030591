import { useToken } from "hooks/useToken";

import api from "services/api";

export interface JointOwner {
  fullName: string;
  cpf: string;
  rg: string;
  email: string;
  cellphone: string;
  phone: string;
  birthDate: string;
  id: string;
  unitId: string;
}

interface JointOwnerDTO {
  full_name: string;
  cpf: string;
  rg: string;
  email: string;
  cellphone: string;
  phone: string;
  birth_date: string;
  unit_id: string;
  id: string;
}

interface JointOwnersRepositoryGetListResponse {
  data: {
    data: JointOwnerDTO[]
  };
}

interface JointOwnerRepositoryGetOneResponse {
  data: JointOwnerDTO;
}

type JointOwners = JointOwner[];
type JointOwnersDTO = JointOwnerDTO[];

export const useJointOwnerRepository = () => {
  const { sessionRequestDefaultConfig } = useToken();

  const translateJointOwnerFromDTO = (jointOwner: JointOwnerDTO) => {
    return {
      fullName: jointOwner.full_name,
      cpf: jointOwner.cpf,
      rg: jointOwner.rg,
      email: jointOwner.email,
      cellphone: jointOwner.cellphone,
      phone: jointOwner.phone,
      unitId: jointOwner.unit_id,
      birthDate: jointOwner.birth_date,
      id: jointOwner.id
    };
  };

  const translateJointOwnerToDTO = (jointOwner: JointOwner) => {
    return {
      full_name: jointOwner.fullName,
      cpf: jointOwner.cpf,
      rg: jointOwner.rg,
      email: jointOwner.email,
      cellphone: jointOwner.cellphone,
      phone: jointOwner.phone,
      unit_id: jointOwner.unitId,
      birth_date: jointOwner.birthDate,
      id: jointOwner.id
    };
  };

  const adaptFromDTOList = (DTO: JointOwnersDTO) => {
    return DTO.map((jointOwner: JointOwnerDTO) =>
      translateJointOwnerFromDTO(jointOwner));
  };

  const adaptFromDTO = (jointOwnerDto: JointOwnerDTO) => {
    return translateJointOwnerFromDTO(jointOwnerDto);
  };

  const adaptToDTO = (jointOwner: JointOwner) => {
    return translateJointOwnerToDTO(jointOwner);
  };

  const getJointOwners = async(unitId: string): Promise<JointOwners> => {
    const response: JointOwnersRepositoryGetListResponse = await api.get(
      `joint-owner/?unit_id=${unitId}`,
      sessionRequestDefaultConfig
    );
    return adaptFromDTOList(response.data.data);
  };

  const getJointOwner = async(id: string): Promise<JointOwner> => {
    const response: JointOwnerRepositoryGetOneResponse =
      await api.get(`joint-owner/${id}`, sessionRequestDefaultConfig);
    return adaptFromDTO(response.data);
  };

  const postJointOwner = async(data: JointOwner):
    Promise<JointOwner> => {
    const response: JointOwnerRepositoryGetOneResponse =
      await api.post(
        "joint-owner",
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );

    return adaptFromDTO(response.data);
  };

  const putJointOwner = async(data: JointOwner, id: string):
    Promise<JointOwner> => {
    const response: JointOwnerRepositoryGetOneResponse =
      await api.put(
        `joint-owner/${id}`,
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data);
  };

  const deleteJointOwner = async(id: string):
    Promise<JointOwner> => {
    const response: JointOwnerRepositoryGetOneResponse =
      await api.delete(
        `joint-owner/${id}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data);
  };

  return {
    getJointOwners,
    getJointOwner,
    postJointOwner,
    deleteJointOwner,
    putJointOwner
  };
};
