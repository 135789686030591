import { RefObject, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import { Employee, useCondominiumEmployeeRepository } from "hooks/useCondominiumEmployeeRepository";

import { makeMessageError } from "utils/axiosUtils";

import { EmployeeForm, EmployeeFormDataProps } from ".";
import { initialEmployeeState } from "../constants";

export const EmployeeFormCreate = () => {
  const navigate = useNavigate();
  const { id: condominiumId } = useParams();
  const toast = useToast();
  const [dataForm, setDataForm] = useState<EmployeeFormDataProps>(initialEmployeeState);
  const dataFormState = {
    dataForm,
    setDataForm
  };
  const { postCondominiumEmployee } = useCondominiumEmployeeRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  const storeEmployee = (data: Employee) => {
    postCondominiumEmployee(data)
      .then((_r) => {
        toast({
          title: `Funcionário ${data.fullName} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/employees`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <EmployeeForm
      formRef={formRef}
      storeEmployee={storeEmployee}
      dataFormState={dataFormState}
    />
  );
};
