import { useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { Provider } from "hooks/useCondominiumProviderRepository";
import { useEffectGetOneProvider } from "hooks/useEffectGetOneProvider";

import { Loading } from "components/Loading";

import { NUMBER_DEFAULT_VALUE, STRING_DEFAULT_VALUE } from "utils/constants";

import { initialProviderState } from "../constants";
import { ProviderPreview } from "../ProviderPreview";
import { ProviderDetailsContainer } from "./ProviderDetailsContainer";

export const ProviderDetails = () => {
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState<Provider>(
    initialProviderState
  );

  useEffectGetOneProvider(setProvider, setLoading);

  return (
    <ProviderDetailsContainer fullName={provider.fullName} >
      {loading ? renderLoading(loading) : renderProviderDetails(provider)}
    </ProviderDetailsContainer>
  );
};

const renderLoading = (loading: boolean) => {
  return (
    <>
      <Loading visible={loading} />
    </>
  );
};

const renderProviderDetails = (data: Provider) => {
  return (
    <Flex justify={"space-between"}>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Nome"}</Text>
          <Text color={"gray.600"}>
            {data.fullName || STRING_DEFAULT_VALUE}
          </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Endereço"}</Text>
          <Text color={"gray.600"}>
            {data.address || STRING_DEFAULT_VALUE}
          </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"CNPJ"}</Text>
          <Text color={"gray.600"}>
            {data.cnpj || STRING_DEFAULT_VALUE}
          </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Telefone"}</Text>
          <Text color={"gray.600"}>
            {data.phone || STRING_DEFAULT_VALUE}
          </Text>
        </Box>
      </Box>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Reviews"}</Text>
          <Text color={"gray.600"}>
            {data.reviews || NUMBER_DEFAULT_VALUE}
          </Text>
        </Box>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Segmento"}</Text>
          <Text color={"gray.600"}>
            {data.segment || STRING_DEFAULT_VALUE}
          </Text>
        </Box>
      </Box>
      <Box width={320}>
        <ProviderPreview
          fullName={data.fullName}
          address={data.address}
          segment={data.segment}
          id={data.id}
        />
      </Box>
    </Flex>
  );
};
