
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Stack } from "@chakra-ui/react";

import { Unit, useCondominiumUnitRepository } from "hooks/useCondominiumUnitRepository";

import { Loading } from "components/Loading";

import { UnitPreview } from "./UnitPreview";

export const Units = () => {
  const [units, setUnits] = useState<Unit[]>([]);
  const { getCondominiumUnits } = useCondominiumUnitRepository();

  const [loading, setLoading] = useState<boolean>(false);
  const navigator = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!id) return;
      const data = await getCondominiumUnits(id);
      setUnits(data);
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        align="center"
        marginBottom={10}
        justifyContent={"start"}
      >
        <Button
          colorScheme="green"
          variant={"outline"}
          onClick={() => navigator(
            `/dashboard/condominiums/condominium/${id}/units/create`
          )}
        >
          <AddIcon w={6} h={6} marginRight={2} />
          Cadastrar Unidade
        </Button>
      </Stack>
      <Loading visible={loading} />
      <SimpleGrid
        columns={{
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          "2xl": 6,
        }}
        spacing={10}
      >
        {units.map((unit) => (
          <UnitPreview
            key={unit.id}
            number={unit.number}
            block={unit.block}
            ownerFullName={unit.ownerFullName}
            id={unit.id}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
