import React from "react";

import { Spinner, Stack } from "@chakra-ui/react";

interface IProps {
  visible?: boolean;
}
export const Loading: React.FC<IProps> = ({ visible }) => {
  return (
    <Stack
      direction="row"
      spacing={4}
      align="center"
      marginBottom="8px"
      justifyContent={"center"}
    >
      {visible && (
        <Spinner
          justifyContent={"center"}
          margin={"auto"}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
    </Stack>
  );
};
