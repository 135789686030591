
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Stack } from "@chakra-ui/react";

import { Employee, useCondominiumEmployeeRepository } from "hooks/useCondominiumEmployeeRepository";

import { Loading } from "components/Loading";

import { EmployeePreview } from "./EmployeePreview";

export const Employees = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const { getCondominiumEmployees } = useCondominiumEmployeeRepository();

  const [loading, setLoading] = useState<boolean>(false);
  const navigator = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!id) return;
      const data = await getCondominiumEmployees(id);
      setEmployees(data);
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        align="center"
        marginBottom={10}
        justifyContent={"start"}
      >
        <Button
          colorScheme="green"
          variant={"outline"}
          onClick={() => navigator(
            `/dashboard/condominiums/condominium/${id}/employees/create`
          )}
        >
          <AddIcon w={6} h={6} marginRight={2} />
          Cadastrar Funcionário
        </Button>
      </Stack>
      <Loading visible={loading} />
      <SimpleGrid
        columns={{
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          "2xl": 6,
        }}
        spacing={10}
      >
        {employees.map((employee) => (
          <EmployeePreview
            key={employee.id}
            admissionDate={employee.admissionDate}
            fullName={employee.fullName}
            role={employee.role}
            id={employee.id}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
