import { ChangeEvent, RefObject } from "react";
import { useParams } from "react-router-dom";

import { Button, Container } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { Service } from "hooks/useCondominiumServiceRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { ServicePreview } from "components/Condominiums/Services/ServicePreview";
import { Input } from "components/Form/Input";
import { Switch } from "components/Form/Switch";

import { FormTypes } from "services/utils/FormTypes";

export interface ServiceFormDataProps {
  name: string;
  date: string;
  price: string;
  serviceOrder: string;
}

interface ServiceFormProps {
  formRef: RefObject<FormHandles>;
  storeService: (data: Service) => void;
  isMaintenanceState: {
    isMaintenance: 0 | 1;
    setIsMaintenance: React.Dispatch<React.SetStateAction<0 | 1>>
  };
  dataFormState: {
    dataForm: ServiceFormDataProps,
    setDataForm: React.Dispatch<React.SetStateAction<ServiceFormDataProps>>
  }
}

export const ServiceForm = ({
  formRef,
  storeService,
  dataFormState: { dataForm, setDataForm },
  isMaintenanceState: { isMaintenance, setIsMaintenance }
}: ServiceFormProps) => {
  const { id: condominiumId } = useParams();
  const { formIsValid } = useYupValidation();

  const handleSubmitForm = async (data: Service) => {
    if (!condominiumId) return;
    if (!(await formIsValid(formRef, FormTypes.STORE_SERVICE, data)))
      return;

    storeService({
      ...data,
      condominiumId,
      isMaintenance: isMaintenance
    });
  };

  const handleChangeForm = (_data?: object) => {
    if (!formRef.current) return;
    setDataForm(formRef.current.getData() as ServiceFormDataProps);
  };

  const handleIsMaintenanceChange = (e: ChangeEvent<HTMLInputElement>) => setIsMaintenance(e.target.checked ? 1 : 0);

  return (
    <>
      <Container margin={0} justifyContent="left">
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          onChange={handleChangeForm}
        >
          <Input name={"name"} label={"Nome"} marginBottom={5} />
          <Input
            type={"date"}
            name={"date"}
            label={"Data"}
            marginBottom={5}
          />
          <Input
            name={"price"}
            label={"Preço"}
            marginBottom={5}
          />
          <Switch
            name={"isMaintenance"}
            label={"É manutenção?"}
            marginBottom={5}
            isChecked={isMaintenance === 1 ? true : false}
            onChange={handleIsMaintenanceChange}
          />
          <Input
            name={"serviceOrder"}
            label={"Ordem de serviço"}
            marginBottom={5}
          />
          <Button
            type={"submit"}
            marginTop={5}
            colorScheme={"teal"}
            marginBottom={5}
          >
            Salvar
          </Button>
        </Form>
      </Container>
      <ServicePreview
        date={dataForm.date}
        name={dataForm.name}
        price={dataForm.price}
      />
    </>
  );
};
