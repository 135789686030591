import { ActionTypes } from "./types";

export function addSessionToken(token: string) {
  return {
    type: ActionTypes.addSessionToken,
    payload: {
      token,
    },
  };
}
