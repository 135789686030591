import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { JointOwner, useJointOwnerRepository } from "hooks/useJointOwnerRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

import { Loading } from "components/Loading";

interface IJointOwnerListProps {
  unitId: string;
}

export const JointOwnersList = ({ unitId }: IJointOwnerListProps) => {
  const { abortRepository } = useRepositoryController();

  const navigator = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [jointOwners, setJointOwners] = useState<JointOwner[]>([]);

  const { getJointOwners } = useJointOwnerRepository();

  const useEffectGetOnlyOnceCondominiumStoreState = () =>
    useEffect(() => {
      setLoading(true);
      (async () => {
        const jointOwner = await getJointOwners(unitId);
        setJointOwners(jointOwner);
        setLoading(false);
      })();

      return () => abortRepository();
    }, []);

  useEffectGetOnlyOnceCondominiumStoreState();

  function handleClickRegister(){
    navigator("joint-owner/create");
  }

  function handleClickEdit(jointOwnerId : string){
    navigator(`joint-owner/${jointOwnerId}/edit`);
  }

  return (
    <Box>
      <Flex justifyContent={{"sm": "space-between"}}>
        <Heading>Condôminos</Heading>
        <Button colorScheme="green" onClick={handleClickRegister}>
          Cadastrar Condômino
        </Button>
      </Flex>
      <TableContainer>
        <Table >
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>E-mail</Th>
              <Th>...</Th>
            </Tr>
          </Thead>
          <Loading visible={loading} />
          <Tbody>
            {jointOwners.map((joint, index) => (
              <Fragment key={index}>
                <Tr>
                  <Td>{joint.fullName}</Td>
                  <Td>{joint.email}</Td>
                  <Td>
                    <Button className="m-0 p-0" onClick={() => handleClickEdit(joint.id)}>
                      <EditIcon
                        boxSize={6}
                        bg="white"
                        color="green"
                        borderColor="gray.200"
                      />
                    </Button>
                  </Td>
                </Tr>
              </Fragment>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
