const STRING_DEFAULT_VALUE = "Não Informado";
const STRING_EMPTY_VALUE = "";

const ID_DEFAULT_VALUE = -1;

const NUMBER_DEFAULT_VALUE = 0;

export {
  STRING_DEFAULT_VALUE,
  STRING_EMPTY_VALUE,
  ID_DEFAULT_VALUE,
  NUMBER_DEFAULT_VALUE,
};
