import {
  Box,
  Center,
  Heading,
  Stack,
  Image,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";

interface ICardOnboardingProps {
  handleClickButton : () => void,
  buttonTitle: string,
  imageCard: string,
  title: string,
  descriptions : string
}


export const CardOnboarding = ({
  buttonTitle,
  handleClickButton,
  imageCard,
  title,
  descriptions
}: ICardOnboardingProps) => {

  return (
    <Stack>
      <Box
        maxW={"360px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Box bg={"gray.100"} mt={-6} mx={-6} mb={6} pos={"relative"}>
          <Center>
            <Image src={imageCard} height={320} />
          </Center>
        </Box>
        <Stack>
          <Heading
            color={useColorModeValue("gray.700", "white")}
            fontSize={"xl"}
            fontFamily={"body"}
          >
            {title}
          </Heading>
          
          <p dangerouslySetInnerHTML={{__html: descriptions}} />
          
          <Button
            onClick={() => handleClickButton()}
            mt={10}
            w={"full"}
            bg={"green.400"}
            color={"white"}
            rounded={"xl"}
            boxShadow={"0 5px 20px 0px rgb(72 187 120 / 43%)"}
            _hover={{
              bg: "green.500",
            }}
            _focus={{
              bg: "green.500",
            }}
          >
            {buttonTitle}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
