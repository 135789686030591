import produce from "immer";
import { Reducer } from "redux";

import { ActionTypes, IUserSessionState } from "./types";

const INITIAL_STATE: IUserSessionState = {
  token: "",
};

const UserSession: Reducer<IUserSessionState> = (
  state: IUserSessionState = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.addSessionToken: {
        const { token } = action.payload;
        draft.token = token;
        break;
      }
      default: {
        return draft;
      }
    }
  });
};

export default UserSession;
