import { RefObject, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import {
  ICondominium,
  useCondominiumRepository,
} from "hooks/useCondominiumRepository";

import { makeMessageError } from "utils/axiosUtils";

import { CondominiumForm } from ".";

export const CondominiumFormCreate = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { postCondominium } = useCondominiumRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  const storeCondominium = (data: ICondominium) => {
    postCondominium(data)
      .then((_r) => {
        toast({
          title: `Condomínio ${data.full_name} salvo com sucesso`,
          status: "success",
        });
        navigate("/dashboard/condominiums");
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <CondominiumForm formRef={formRef} storeCondominium={storeCondominium} />
  );
};
