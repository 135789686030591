import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import { Provider, useCondominiumProviderRepository } from "hooks/useCondominiumProviderRepository";
import { useEffectGetOneProvider } from "hooks/useEffectGetOneProvider";

import { Loading } from "components/Loading";

import { makeMessageError } from "utils/axiosUtils";

import { ProviderForm, ProviderFormDataProps } from ".";
import { initialProviderState } from "../constants";

export const ProviderFormEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const { id: condominiumId, providerId } = useParams();
  const [provider, setProvider] = useState<Provider>(
    initialProviderState
  );

  const [dataForm, setDataForm] = useState<ProviderFormDataProps>(initialProviderState);
  const dataFormState = {
    dataForm,
    setDataForm
  };

  const { putCondominiumProvider } = useCondominiumProviderRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  useEffectGetOneProvider(setProvider, setLoading);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.setData({ ...provider });
    dataFormState.setDataForm(formRef.current.getData() as ProviderFormDataProps);
  }, [provider]);

  const storeProvider = (data: Provider) => {
    if (!condominiumId) return;
    putCondominiumProvider(data, condominiumId)
      .then((_r) => {
        toast({
          title: `Fornecedor ${data.fullName} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/providers/provider/${providerId}`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <>
      {loading ? (
        <Loading visible={loading} />
      ) : (
        <ProviderForm
          formRef={formRef}
          storeProvider={storeProvider}
          dataFormState={dataFormState}
        />
      )}
    </>
   
  );
};
