import { useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { Unit } from "hooks/useCondominiumUnitRepository";
import { useEffectGetOneUnit } from "hooks/useEffectGetOneUnit";

import { Loading } from "components/Loading";

import { initialUnitState } from "../constants";
import { JointOwnersList } from "../JointOwner/JointOwnerList";
import { UnitPreview } from "../UnitPreview";
import { UnitDetailsContainer } from "./UnitDetailsContainer";

export const UnitDetails = () => {
  const [loading, setLoading] = useState(true);
  const [unit, setUnit] = useState<Unit>(initialUnitState);

  useEffectGetOneUnit(setUnit, setLoading);

  return (
    <UnitDetailsContainer
      number={unit.number}
      block={unit.block}
    >
      <>
        {loading ? renderLoading(loading) : renderUnitDetails(unit)}
      </>
    </UnitDetailsContainer>
  );
};

const renderLoading = (loading: boolean) => {
  return (
    <>
      <Loading visible={loading} />
    </>
  );
};

const renderUnitDetails = (data: Unit) => {
  return (
    <div>
      <Flex justify={"space-between"}>
        <Box padding={10}>
          <Box marginBottom={5}>
            <Text fontWeight={600}>{"Número"}</Text>
            <Text color={"gray.600"}>{data.number || ""} </Text>
          </Box>

          <Box marginBottom={5}>
            <Text fontWeight={600}>{"Bloco"}</Text>
            <Text color={"gray.600"}>{data.block || ""} </Text>
          </Box>

          <Box marginBottom={5}>
            <Text fontWeight={600}>{"RGI"}</Text>
            <Text color={"gray.600"}>{data.rgi || ""} </Text>
          </Box>

          <Box marginBottom={5}>
            <Text fontWeight={600}>{"Nome do Proprietário"}</Text>
            <Text color={"gray.600"}>{data.ownerFullName || " "}</Text>
          </Box>
        </Box>
        <Box padding={10}>
          <Box marginBottom={5}>
            <Text fontWeight={600}>{"Nome do Principal Co-proprietário"}</Text>
            <Text color={"gray.600"}>
              {data.jointOwnerPrincipalFullName || " "}
            </Text>
          </Box>

          <Box marginBottom={5}>
            <Text fontWeight={600}>{"Máximo de Garagens"}</Text>
            <Text color={"gray.600"}>{data.maxGarage || ""}</Text>
          </Box>
        </Box>
        <Box width={320}>
          <UnitPreview
            number={data.number}
            block={data.block}
            ownerFullName={data.ownerFullName}
            id={data.id}
          />
        </Box>
      </Flex>

      <JointOwnersList unitId={data.id}   />
    </div>
  );
};
