import { RefObject } from "react";
import { useParams } from "react-router-dom";

import { Button, Container } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { Provider } from "hooks/useCondominiumProviderRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { ProviderPreview } from "components/Condominiums/Providers/ProviderPreview";
import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";

export interface ProviderFormDataProps {
  fullName: string;
  cnpj: string;
  address: string;
  email: string;
  phone: string;
  segment: string;
  reviews: number;
}

interface ProviderFormProps {
  formRef: RefObject<FormHandles>;
  storeProvider: (data: Provider) => void;
  dataFormState: {
    dataForm: ProviderFormDataProps,
    setDataForm: React.Dispatch<React.SetStateAction<ProviderFormDataProps>>
  }
}

export const ProviderForm = ({
  formRef,
  storeProvider,
  dataFormState: { dataForm, setDataForm },
}: ProviderFormProps) => {
  const { id: condominiumId } = useParams();
  const { formIsValid } = useYupValidation();

  const handleSubmitForm = async (data: Provider) => {
    if (!condominiumId) return;
    if (!(await formIsValid(formRef, FormTypes.STORE_PROVIDER, data)))
      return;
    storeProvider({
      ...data,
      condominiumId: Number(condominiumId),
    });
  };

  const handleChangeForm = (_data?: object) => {
    if (!formRef.current) return;
    setDataForm(formRef.current.getData() as ProviderFormDataProps);
  };

  return (
    <>
      <Container margin={0} justifyContent="left">
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          onChange={handleChangeForm}
        >
          <Input name={"fullName"} label={"Nome"} marginBottom={5} />
          <Input
            name={"cnpj"}
            label={"CNPJ"}
            marginBottom={5}
          />
          <Input
            name={"address"}
            label={"Endereço"}
            marginBottom={5}
          />
          <Input
            type={"email"}
            name={"email"}
            label={"E-mail"}
            marginBottom={5}
          />
          <Input
            name={"phone"}
            label={"Telefone"}
            marginBottom={5}
          />
          <Input
            name={"segment"}
            label={"Segmento"}
            marginBottom={5}
          />
          <Input
            type={"number"}
            name={"reviews"}
            label={"Reviews"}
            marginBottom={5}
          />
          <Button
            type={"submit"}
            marginTop={5}
            colorScheme={"teal"}
            marginBottom={5}
          >
            Salvar
          </Button>
        </Form>
      </Container>
      <ProviderPreview
        fullName={dataForm.fullName}
        address={dataForm.address}
        segment={dataForm.segment}
      />
    </>
  );
};
