
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Stack } from "@chakra-ui/react";

import { Service, useCondominiumServiceRepository } from "hooks/useCondominiumServiceRepository";

import { Loading } from "components/Loading";

import { ServicePreview } from "./ServicePreview";

export const Services = () => {
  const [services, setServices] = useState<Service[]>([]);
  const { getCondominiumServices } = useCondominiumServiceRepository();

  const [loading, setLoading] = useState<boolean>(false);
  const navigator = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!id) return;
      const data = await getCondominiumServices(id);
      setServices(data);
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        align="center"
        marginBottom={10}
        justifyContent={"start"}
      >
        <Button
          colorScheme="green"
          variant={"outline"}
          onClick={() => navigator(
            `/dashboard/condominiums/condominium/${id}/services/create`
          )}
        >
          <AddIcon w={6} h={6} marginRight={2} />
          Cadastrar Serviço
        </Button>
      </Stack>
      <Loading visible={loading} />
      <SimpleGrid
        columns={{
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          "2xl": 6,
        }}
        spacing={10}
      >
        {services.map((service) => (
          <ServicePreview
            key={service.id}
            date={service.date}
            name={service.name}
            price={service.price}
            id={service.id}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
