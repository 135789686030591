import { useNavigate, useParams } from "react-router-dom";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";

interface Props {
  number: string;
  block: string;
  ownerFullName: string;
  id?: string;
}

export const UnitPreview = ({
  id,
  block,
  number,
  ownerFullName
}: Props) => {
  const navigator = useNavigate();
  const { id: condominiumId } = useParams();

  const handleNavigateToUnitDetails = () => {
    if (!id) return;
    navigator(`/dashboard/condominiums/condominium/${condominiumId}/units/unit/${id}`);
  };

  const handleNavigateToUnitEditForm = () => {
    if (!id) return;
    navigator(
      `/dashboard/condominiums/condominium/${condominiumId}/units/unit/${id}/edit`
    );
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      maxHeight="min"
      minHeight={120}
      minWidth="120px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        onClick={handleNavigateToUnitDetails}
        style={{
          cursor: "pointer",
        }}
        p="6"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Text
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {ownerFullName}
        </Text>
        <Text>{number}</Text>
        <Text>{block}</Text>
      </Box>
      {id && (
        <Button
          variant={"ghost"}
          onClick={handleNavigateToUnitEditForm}
          display="flex"
          mt="2"
          alignItems="center"
        >
          <EditIcon
            boxSize={6}
            bg="white"
            color="green"
            borderColor="gray.200"
          />
        </Button>
      )}
    </Box>
  );
};
