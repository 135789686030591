import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Stack } from "@chakra-ui/react";

import {
  ICondominium,
  useCondominiumRepository,
} from "hooks/useCondominiumRepository";
import { useRepositoryController } from "hooks/useRepositoryController";

import { CondominiumPreview } from "components/Condominiums/CondominiumPreview";
import { Loading } from "components/Loading";

export const ListCondominiums: React.FC = () => {
  const { abortRepository } = useRepositoryController();

  const navigator = useNavigate();
  const [condominiums, setCondominiums] = useState<ICondominium[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { getCondominiums } = useCondominiumRepository();

  const useEffectGetOnlyOnceCondominiumStoreState = () =>
    useEffect(() => {
      setLoading(true);
      (async () => {
        const response = await getCondominiums();
        setCondominiums(response.data.data);
        setLoading(false);
      })();

      return () => abortRepository();
    }, []);

  useEffectGetOnlyOnceCondominiumStoreState();

  return (
    <Box>
      <Stack
        direction="row"
        spacing={4}
        align="center"
        marginBottom="8px"
        justifyContent={"start"}
      >
        <Button
          colorScheme="teal"
          variant={"solid"}
          onClick={() => navigator("/dashboard/condominiums/create")}
        >
          <AddIcon w={6} h={6} marginRight={2} />
          Cadastrar Imóvel
        </Button>
      </Stack>

      <Loading visible={loading} />

      <SimpleGrid
        columns={{
          sm: 2,
          md: 3,
          lg: 4,
        }}
        spacing={10}
      >
        {condominiums.map((condominium, index) => (
          <CondominiumPreview
            key={index}
            full_name={condominium.full_name}
            address={condominium.address}
            id={condominium.id}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
