import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";


import { useEffectGetOneJointOwner } from "hooks/useEffectGetOneJointOwner";
import { JointOwner, useJointOwnerRepository } from "hooks/useJointOwnerRepository";

import { Loading } from "components/Loading";

import { makeMessageError } from "utils/axiosUtils";

import { JointOwnerForm, JointOwnerFormDataProps } from ".";
import { initialJointOwnerState } from "../../constants";

export const JointOwnerFormEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { id: condominiumId, unitId } = useParams();
  const [jointOwner, setJointOwners] = useState<JointOwner>(initialJointOwnerState);
  const [dataForm, setDataForm] = useState<JointOwnerFormDataProps>(
    initialJointOwnerState
  );
  const dataFormState = {
    dataForm,
    setDataForm,
  };
  const { putJointOwner } = useJointOwnerRepository();

  const formRef: RefObject<FormHandles> = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffectGetOneJointOwner(setJointOwners, setLoading);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.setData({ ...jointOwner });
    setDataForm(formRef.current.getData() as JointOwnerFormDataProps);
  }, [jointOwner]);

  const storeJointOwner = (data: JointOwner) => {
    if (!condominiumId) return;
    putJointOwner(data, condominiumId)
      .then((_r) => {
        toast({
          title: `Condominio ${data.fullName} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/units/unit/${unitId}`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <>
      <Box>
        <Loading visible={loading} />
        
        <JointOwnerForm
          formRef={formRef}
          storeJointOwner={storeJointOwner}
          dataFormState={dataFormState}
        />
      </Box>
    </>
  );
};
