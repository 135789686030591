import { Employee } from "hooks/useCondominiumEmployeeRepository";

import {
  ID_DEFAULT_VALUE,
  NUMBER_DEFAULT_VALUE,
  STRING_EMPTY_VALUE
} from "utils/constants";

export const initialEmployeeState: Employee = {
  fullName: STRING_EMPTY_VALUE,
  role: STRING_EMPTY_VALUE,
  admissionDate: STRING_EMPTY_VALUE,
  outsourced: STRING_EMPTY_VALUE,
  salary: NUMBER_DEFAULT_VALUE,
  id: ID_DEFAULT_VALUE,
  condominiumId: ID_DEFAULT_VALUE,
};
