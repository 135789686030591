import { useNavigate, useParams } from "react-router-dom";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";

import { dateFormatter } from "utils/format";

interface Props {
  fullName: string;
  role: string;
  admissionDate: string;
  id?: number;
}

export const EmployeePreview = ({
  id,
  fullName,
  admissionDate,
  role
}: Props) => {
  const navigator = useNavigate();
  const { id: condominiumId } = useParams();

  const handleNavigateToEmployeeDetails = () => {
    if (!id) return;
    navigator(`/dashboard/condominiums/condominium/${condominiumId}/employees/employee/${id}`);
  };

  const handleNavigateToEmployeeEditForm = () => {
    if (!id) return;
    navigator(
      `/dashboard/condominiums/condominium/${condominiumId}/employees/employee/${id}/edit`
    );
  };

  const formattedAdmissionDate = (
    admissionDate && dateFormatter.format(new Date(admissionDate))
  ) || "";

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      maxHeight="min"
      minHeight={120}
      minWidth="120px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        onClick={handleNavigateToEmployeeDetails}
        style={{
          cursor: "pointer",
        }}
        p="6"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Text
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {fullName}
        </Text>
        <Text>{role}</Text>
        <Text>{formattedAdmissionDate}</Text>
      </Box>
      {id && (
        <Button
          variant={"ghost"}
          onClick={handleNavigateToEmployeeEditForm}
          display="flex"
          mt="2"
          alignItems="center"
        >
          <EditIcon
            boxSize={6}
            bg="white"
            color="green"
            borderColor="gray.200"
          />
        </Button>
      )}
    </Box>
  );
};
