
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Stack } from "@chakra-ui/react";

import { Provider, useCondominiumProviderRepository } from "hooks/useCondominiumProviderRepository";

import { Loading } from "components/Loading";

import { ProviderPreview } from "./ProviderPreview";

export const Providers = () => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const { getCondominiumProviders } = useCondominiumProviderRepository();

  const [loading, setLoading] = useState<boolean>(false);
  const navigator = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!id) return;
      const data = await getCondominiumProviders(id);
      setProviders(data);
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        align="center"
        marginBottom={10}
        justifyContent={"start"}
      >
        <Button
          colorScheme="green"
          variant={"outline"}
          onClick={() => navigator(
            `/dashboard/condominiums/condominium/${id}/providers/create`
          )}
        >
          <AddIcon w={6} h={6} marginRight={2} />
          Cadastrar Fornecedor
        </Button>
      </Stack>
      <Loading visible={loading} />
      <SimpleGrid
        columns={{
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          "2xl": 6,
        }}
        spacing={10}
      >
        {providers.map((provider) => (
          <ProviderPreview
            key={provider.id}
            fullName={provider.fullName}
            address={provider.address}
            segment={provider.segment}
            id={provider.id}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
