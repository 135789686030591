import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { addSessionToken } from "services/store/modules/session/actions";

export const useToken = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["token"]);

  const setUserToken = useCallback(
    (token: string) => {
      setCookie("token", token);
      dispatch(addSessionToken(token));
    },
    [dispatch, setCookie]
  );

  const token: string = cookies.token;

  const sessionRequestDefaultConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return { setUserToken, token, sessionRequestDefaultConfig };
};
