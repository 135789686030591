import { RefObject } from "react";
import { useParams } from "react-router-dom";

import { Button, Container } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { Employee } from "hooks/useCondominiumEmployeeRepository";
import { useYupValidation } from "hooks/useYupValidation";

import { EmployeePreview } from "components/Condominiums/Employees/EmployeePreview";
import { Input } from "components/Form/Input";

import { FormTypes } from "services/utils/FormTypes";

export interface EmployeeFormDataProps {
  fullName: string;
  role: string;
  admissionDate: string;
  outsourced: string;
  salary: number;
}

interface EmployeeFormProps {
  formRef: RefObject<FormHandles>;
  storeEmployee: (data: Employee) => void;
  dataFormState: {
    dataForm: EmployeeFormDataProps,
    setDataForm: React.Dispatch<React.SetStateAction<EmployeeFormDataProps>>
  }
}

export const EmployeeForm = ({
  formRef,
  storeEmployee,
  dataFormState: { dataForm, setDataForm },
}: EmployeeFormProps) => {
  const { id: condominiumId } = useParams();
  const { formIsValid } = useYupValidation();

  const handleSubmitForm = async (data: Employee) => {
    if (!condominiumId) return;
    if (!(await formIsValid(formRef, FormTypes.STORE_EMPLOYEE, data)))
      return;
    storeEmployee({
      ...data,
      condominiumId: Number(condominiumId),
      salary: Number(data.salary),
    });
  };

  const handleChangeForm = (_data?: object) => {
    if (!formRef.current) return;
    setDataForm(formRef.current.getData() as EmployeeFormDataProps);
  };

  return (
    <>
      <Container margin={0} justifyContent="left">
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          onChange={handleChangeForm}
        >
          <Input name={"fullName"} label={"Nome completo"} marginBottom={5} />
          <Input
            name={"role"}
            label={"Cargo"}
            marginBottom={5}
          />
          <Input
            type={"date"}
            name={"admissionDate"}
            label={"Data da Admissão"}
            marginBottom={5}
          />
          <Input
            name={"outsourced"}
            label={"Terceirizado"}
            marginBottom={5}
          />
          <Input
            name={"salary"}
            label={"Salário"}
            marginBottom={5}
          />
          <Button
            type={"submit"}
            marginTop={5}
            colorScheme={"teal"}
            marginBottom={5}
          >
            Salvar
          </Button>
        </Form>
      </Container>
      <EmployeePreview
        fullName={dataForm.fullName}
        admissionDate={dataForm.admissionDate}
        role={dataForm.role}
      />
    </>
  );
};
