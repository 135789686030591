import { Heading, Text } from "@chakra-ui/react";

export const CreateEmployeeFormLegend = () => {
  return (
    <legend>
      <Heading>Cadastro de Funcionário</Heading>
      <Text>Informe os dados do funcionário para cadastrar no Condomínio.</Text>
    </legend>
  );
};

export const EditEmployeeFormLegend = () => {
  return (
    <legend>
      <Heading>Alteração de Funcionário</Heading>
      <Text>Altere os dados do funcionário para salvar no Condomínio.</Text>
    </legend>
  );
};
