import { ProviderFormCreate } from "components/Condominiums/Providers/ProviderForm/ProviderFormCreate";
import { ProviderFormEdit } from "components/Condominiums/Providers/ProviderForm/ProviderFormEdit";
import {
  CreateProviderFormLegend,
  EditProviderFormLegend
} from "components/Condominiums/Providers/ProviderForm/ProviderFormLegends";

export const PROVIDER_FORM_COMPONENTS_PER_TYPE = {
  edit: ProviderFormEdit,
  create: ProviderFormCreate,
};

export const PROVIDER_FORM_LEGENDS_PER_TYPE = {
  edit: EditProviderFormLegend,
  create: CreateProviderFormLegend,
};
