import { FlexContainer } from "layouts/FlexContainer";

import { EmployeeDetails } from "components/Condominiums/Employees/EmployeeDetails";

export const ViewEmployee = () => {
  return (
    <FlexContainer>
      <EmployeeDetails />
    </FlexContainer>
  );
};
