import { useNavigate } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { AxiosError, AxiosResponse } from "axios";

import { useToken } from "hooks/useToken";

import api from "services/api";

export interface ICondominium {
  organization_id: number | null | undefined;
  full_name: string;
  address: string;
  cnpj: string;
  email: string;
  phone: string;
  id?: number;
  type_charge: string;
  name_subsyndic: string;
  unit_quantity: number;
}

export interface ICondominiumRepositoryGetListResponse {
  data: ICondominium[];
}

export const useCondominiumRepository = () => {
  const { token } = useToken();
  const navigator = useNavigate();
  const toast = useToast();

  function throwExceptionInToast(e: AxiosError): never {
    toast({
      title: "Não foi possível recuperar o condominio solicitado.",
      status: "error",
    });
    navigator("/dashboard/condominium");
    throw e;
  }

  async function getCondominiums(): Promise<
    AxiosResponse<ICondominiumRepositoryGetListResponse>
    > {
    return await api.get("condominium", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async function getCondominium(
    id: number
  ): Promise<AxiosResponse<ICondominium>> {
    try {
      return await api.get(`condominium/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      const err = e as AxiosError;
      throwExceptionInToast(err);
    }
  }

  async function putCondominium(
    data: ICondominium,
    id: string
  ): Promise<AxiosResponse<ICondominiumRepositoryGetListResponse>> {
    return await api.put(`condominium/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async function postCondominium(
    data: ICondominium
  ): Promise<AxiosResponse<ICondominiumRepositoryGetListResponse>> {
    return await api.post("condominium", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return {
    getCondominiums,
    getCondominium,
    putCondominium,
    postCondominium,
    // deleteCondominium
  };
};
