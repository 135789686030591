import { AxiosError, AxiosResponse } from "axios";

import { useToken } from "hooks/useToken";

import api from "services/api";

export interface ILoginRepositoryParams {
  email: string;
  password: string;
}

export interface ILoginRespositoryResponse {
  error?: boolean;
  message?: object;
  auth: boolean;
  token: string;
}

export interface ILoginRepositoryError extends AxiosError {
  response: AxiosResponse<ILoginRespositoryResponse>;
}

export const useLoginRepository = () => {
  const { token } = useToken();

  async function loginRepository(
    data: ILoginRepositoryParams
  ): Promise<AxiosResponse<ILoginRespositoryResponse>> {
    return await api.post("auth/login", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return loginRepository;
};
