import { Heading, Text } from "@chakra-ui/react";

export const CreateJointOwnerFormLegend = () => {
  return (
    <legend>
      <Heading>Cadastro de Condomonio </Heading>
      <Text>Informe os dados do Condomonio  para cadastrar no apartamento.</Text>
    </legend>
  );
};

export const EditJointOwnerFormLegend = () => {
  return (
    <legend>
      <Heading>Alteração de Condomonio </Heading>
      <Text>Altere os dados do apartamento para salvar no apartamento.</Text>
    </legend>
  );
};
