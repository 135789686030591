import { Heading, Text } from "@chakra-ui/react";

export const CreateUnitFormLegend = () => {
  return (
    <legend>
      <Heading>Cadastro de Unidade</Heading>
      <Text>Informe os dados da unidade para cadastrar no Condomínio.</Text>
    </legend>
  );
};

export const EditUnitFormLegend = () => {
  return (
    <legend>
      <Heading>Alteração de Unidade</Heading>
      <Text>Altere os dados da unidade para salvar no Condomínio.</Text>
    </legend>
  );
};
