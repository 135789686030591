import { Box, Heading } from "@chakra-ui/react";

interface ICondominiumDetailsContainerProps {
  children: JSX.Element;
  title: string;
}

export const CondominiumDetailsContainer = ({
  children,
  title,
}: ICondominiumDetailsContainerProps) => {
  return (
    <Box width={"100%"}>
      <Heading>{title}</Heading>
      {children}
    </Box>
  );
};
