

import { useToken } from "hooks/useToken";

import api from "services/api";

export interface Service {
  condominiumId: string;
  name: string;
  date: string;
  price: string;
  isMaintenance: number;
  serviceOrder: string;
  id: string;
}

interface ServiceDTO {
  condominium_id: string;
  name: string;
  date: string;
  price: string;
  is_maintenance: number;
  service_order: string;
  id: string;
}

interface CondominiumServicesRepositoryGetListResponse {
  data: {
    data: ServiceDTO[];
  };
}

interface CondominiumServiceRepositoryGetOneResponse {
  data: {
    data: ServiceDTO;
  };
}

type CondominiumServices = Service[];
type CondominiumServicesDTO = ServiceDTO[];

export const useCondominiumServiceRepository = () => {
  const { sessionRequestDefaultConfig } = useToken();

  const translateServiceFromDTO = (serviceDTO: ServiceDTO) => {
    return {
      condominiumId: serviceDTO.condominium_id,
      name: serviceDTO.name,
      date:  serviceDTO.date,
      price: serviceDTO.price,
      isMaintenance: serviceDTO.is_maintenance,
      serviceOrder: serviceDTO.service_order,
      id: serviceDTO.id,
    };
  };

  const translateServiceToDTO = (service: Service) => {
    return {
      condominium_id: service.condominiumId,
      name: service.name,
      date:  service.date,
      price: service.price,
      is_maintenance: service.isMaintenance,
      service_order: service.serviceOrder,
      id: service.id,
    };
  };

  const adaptFromDTOList = (DTO: CondominiumServicesDTO) => {
    return DTO.map((service: ServiceDTO) =>
      translateServiceFromDTO(service));
  };

  const adaptFromDTO = (condominiumServiceDTO: ServiceDTO) => {
    return translateServiceFromDTO(condominiumServiceDTO);
  };

  const adaptToDTO = (service: Service) => {
    return translateServiceToDTO(service);
  };

  const getCondominiumServices = async(condominiumId: string):
    Promise<CondominiumServices> => {
    const response: CondominiumServicesRepositoryGetListResponse =
      await api.get(
        `service/?condominium_id=${condominiumId}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  const getCondominiumService = async(id: string): Promise<Service> => {
    const response: CondominiumServiceRepositoryGetOneResponse =
      await api.get(`service/${id}`, sessionRequestDefaultConfig);
    return adaptFromDTO(response.data.data);
  };

  const postCondominiumService = async(data: Service):
    Promise<Service> => {
    const response: CondominiumServiceRepositoryGetOneResponse =
      await api.post(
        "service",
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const putCondominiumService = async(data: Service, id: string):
    Promise<Service> => {
    const response: CondominiumServiceRepositoryGetOneResponse =
      await api.put(
        `service/${id}`,
        adaptToDTO(data),
        sessionRequestDefaultConfig
      );
    return adaptFromDTO(response.data.data);
  };

  const deleteCondominiumService = async(id: string):
    Promise<CondominiumServices> => {
    const response: CondominiumServicesRepositoryGetListResponse =
      await api.delete(
        `service/${id}`,
        sessionRequestDefaultConfig
      );
    return adaptFromDTOList(response.data.data);
  };

  return {
    getCondominiumServices,
    getCondominiumService,
    postCondominiumService,
    deleteCondominiumService,
    putCondominiumService
  };
};
