import { FlexContainer } from "layouts/FlexContainer";

import { ProviderDetails } from "components/Condominiums/Providers/ProviderDetails";

export const ViewProvider = () => {
  return (
    <FlexContainer>
      <ProviderDetails />
    </FlexContainer>
  );
};
