import React from "react";
import { useSelector } from "react-redux";

import { Box, Heading, useColorModeValue } from "@chakra-ui/react";
import { Form } from "@unform/web";

import { Input } from "components/Form/Input";

import { IStoreState } from "services/store/modules/types";

export const FormSettings = () => {
  const { full_name, organization, email } = useSelector(
    (state: IStoreState) => state.syndic
  );

  function handleSubmit() {
    console.log("no action");
  }

  function handleChange() {
    console.log("no action");
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      style={{ width: "50%" }}
      padding={10}
    >
      <div>
        <Heading
          color={useColorModeValue("gray.700", "white")}
          fontSize={"xl"}
          fontFamily={"body"}
          marginBottom={10}
        >
          Configurações
        </Heading>
        <Form onSubmit={handleSubmit} onChange={handleChange}>
          <Input name={"full_name"} label={"Nome"} marginBottom={5} disabled value={full_name} />
          <Input name={"email"} label={"Email"} marginBottom={5} disabled value={email} />
          <Input
            name={"organization_name"}
            label={"Sua organização"}
            marginBottom={5}
            value={organization.name}
            disabled
          />
          <Input
            name={"type_account"}
            label={"Tipo de Conta"}
            marginBottom={5}
            disabled
            value="FREEMIUM"
          />
        </Form>
      </div>
    </Box>
  );
};
