import { RefObject, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import { Unit, useCondominiumUnitRepository } from "hooks/useCondominiumUnitRepository";

import { makeMessageError } from "utils/axiosUtils";

import { UnitForm, UnitFormDataProps } from ".";
import { initialUnitState } from "../constants";

export const UnitFormCreate = () => {
  const navigate = useNavigate();
  const { id: condominiumId } = useParams();
  const toast = useToast();
  const [dataForm, setDataForm] = useState<UnitFormDataProps>(initialUnitState);
  const dataFormState = {
    dataForm,
    setDataForm
  };
  const { postCondominiumUnit } = useCondominiumUnitRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  const storeUnit = (data: Unit) => {
    postCondominiumUnit(data)
      .then((_r) => {
        toast({
          title: `Unidade número ${data.number} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/units`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <UnitForm formRef={formRef} storeUnit={storeUnit} dataFormState={dataFormState} />
  );
};
