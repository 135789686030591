import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import {
  Unit,
  useCondominiumUnitRepository,
} from "hooks/useCondominiumUnitRepository";
import { useEffectGetOneUnit } from "hooks/useEffectGetOneUnit";

import { Loading } from "components/Loading";

import { makeMessageError } from "utils/axiosUtils";

import { UnitForm, UnitFormDataProps } from ".";
import { initialUnitState } from "../constants";

export const UnitFormEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { id: condominiumId, unitId } = useParams();
  const [unit, setUnit] = useState<Unit>(initialUnitState);
  const [dataForm, setDataForm] = useState<UnitFormDataProps>(
    initialUnitState
  );
  const dataFormState = {
    dataForm,
    setDataForm,
  };
  const { putCondominiumUnit } = useCondominiumUnitRepository();

  const formRef: RefObject<FormHandles> = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffectGetOneUnit(setUnit, setLoading);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.setData({ ...unit });

    setDataForm(formRef.current.getData() as UnitFormDataProps);
  }, [unit]);

  const storeUnit = (data: Unit) => {
    if (!condominiumId) return;
    putCondominiumUnit(data, condominiumId)
      .then((_r) => {
        toast({
          title: `Unidade número ${data.number} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/units/unit/${unitId}`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (
    <div>
      <Box>
        <Loading visible={loading} />

        <UnitForm
          formRef={formRef}
          storeUnit={storeUnit}
          dataFormState={dataFormState}
        />
      </Box>
    </div>
  );
};
