import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { FormHandles } from "@unform/core";

import { Employee, useCondominiumEmployeeRepository } from "hooks/useCondominiumEmployeeRepository";
import { useEffectGetOneEmployee } from "hooks/useEffectGetOneEmployee";

import { Loading } from "components/Loading";

import { makeMessageError } from "utils/axiosUtils";

import { EmployeeForm, EmployeeFormDataProps } from ".";
import { initialEmployeeState } from "../constants";

export const EmployeeFormEdit = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const { id: condominiumId, employeeId } = useParams();
  const [employee, setEmployee] = useState<Employee>(
    initialEmployeeState
  );

  const [dataForm, setDataForm] = useState<EmployeeFormDataProps>(initialEmployeeState);
  const dataFormState = {
    dataForm,
    setDataForm
  };

  const { putCondominiumEmployee } = useCondominiumEmployeeRepository();

  const formRef: RefObject<FormHandles> = useRef(null);

  useEffectGetOneEmployee(setEmployee, setLoading);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.setData({ ...employee });
    dataFormState.setDataForm(formRef.current.getData() as EmployeeFormDataProps);
  }, [employee]);

  const storeEmployee = (data: Employee) => {
    if (!condominiumId) return;
    putCondominiumEmployee(data, condominiumId)
      .then((_r) => {
        toast({
          title: `Funcionário ${data.fullName} salvo com sucesso.`,
          status: "success",
        });
        navigate(
          `/dashboard/condominiums/condominium/${condominiumId}/employees/employee/${employeeId}`
        );
      })
      .catch((err) => {
        toast({ title: `${makeMessageError(err)}`, status: "error" });
      });
  };

  return (

    <>
      {loading ? (
        <Loading visible={loading} />
      ) : (
        <EmployeeForm
          formRef={formRef}
          storeEmployee={storeEmployee}
          dataFormState={dataFormState}
        />
      )}
    </>
    
  );
};
