import { useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { ICondominium } from "hooks/useCondominiumRepository";
import {
  useEffectGetOnlyOnceCondominiumStoreState
} from "hooks/useEffectGetOnlyOnceCondominiumStoreState";

import {
  CondominiumDetailsContainer
} from "components/Condominiums/CondominiumDetails";
import { CondominiumPreview } from "components/Condominiums/CondominiumPreview";
import {
  initialStateCondominium,
} from "components/Condominiums/constants";
import { Loading } from "components/Loading";

import { STRING_DEFAULT_VALUE } from "utils/constants";

export const CondominiumDetails = () => {
  const [loading, setLoading] = useState(true);
  const [condominium, setCondominium] = useState<ICondominium>(
    initialStateCondominium
  );

  useEffectGetOnlyOnceCondominiumStoreState(setCondominium, setLoading);

  return (
    <CondominiumDetailsContainer title={condominium.full_name}>
      {loading ? renderLoading(loading) : renderCondominiumDetails(condominium)}
    </CondominiumDetailsContainer>
  );
};

const renderLoading = (loading: boolean) => {
  return (
    <>
      <Loading visible={loading} />
    </>
  );
};

const renderCondominiumDetails = (data: ICondominium) => {
  return (
    <Flex justify={"space-between"}>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"CNPJ"}</Text>
          <Text color={"gray.600"}>{data.cnpj || STRING_DEFAULT_VALUE} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Email"}</Text>
          <Text color={"gray.600"}>{data.email || STRING_DEFAULT_VALUE} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Telefone"}</Text>
          <Text color={"gray.600"}>{data.phone || STRING_DEFAULT_VALUE} </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Tipo de Cobrança"}</Text>
          <Text color={"gray.600"}>
            {data.type_charge || STRING_DEFAULT_VALUE}
          </Text>
        </Box>
      </Box>
      <Box padding={10}>
        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Nome do Sub-Síndico"}</Text>
          <Text color={"gray.600"}>
            {data.name_subsyndic || STRING_DEFAULT_VALUE}
          </Text>
        </Box>

        <Box marginBottom={5}>
          <Text fontWeight={600}>{"Quantidade de Unidades"}</Text>
          <Text color={"gray.600"}>
            {data.unit_quantity || STRING_DEFAULT_VALUE}
          </Text>
        </Box>
      </Box>
      <Box width={320}>
        <CondominiumPreview
          address={data.address}
          full_name={data.full_name}
          id={data.id}
          redirect={false}
        />
      </Box>
    </Flex>
  );
};
