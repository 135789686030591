import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Flex, useColorModeValue } from "@chakra-ui/react";


import { JOINT_OWNER_COMPONENTS_PER_TYPE, JOINT_OWNER_LEGENDS_PER_TYPE } 
  from "components/Condominiums/Units/JointOwner/JointOwnerForm/constants";
import { UnitFormType } from "components/Condominiums/Units/UnitForm/types";

export const SaveJointOwner = () => {
  const { jointOwnerId } = useParams();
  const [type, setType] = useState<UnitFormType>("create");

  const JointOwnerFormComponent = JOINT_OWNER_COMPONENTS_PER_TYPE[type];
  const JointOwnerFormLegend = JOINT_OWNER_LEGENDS_PER_TYPE[
    type
  ];

  function setupForm() {
    if (jointOwnerId) {
      setType("edit");
    }
  }

  useEffect(() => {
    setupForm();
  }, [jointOwnerId]);

  return (
    <Flex
      bg={useColorModeValue("gray.50", "gray.800")}
      justifyContent={"left"}
      padding={5}
    >
      <Box width="100%">
        <Box textAlign={"left"} py={10} px={2}>
          <JointOwnerFormLegend />
        </Box>
        <Box
          margin={2}
          display="flex"
          flexDirection={{
            base: "column-reverse",
            lg: "row"
          }}
          gap={10}
          justifyContent={{
            base: "center",
            lg: "space-between"
          }}
        >
          <JointOwnerFormComponent />
        </Box>
      </Box>
    </Flex>
  );
};
